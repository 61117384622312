import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { getListClassification } from "../../actions/ClassificationAction";
import { useStateValue } from "../../contexto/store";
import { saveClassificationAction } from "../../actions/ClassificationAction";
import { deleteClassification } from "../../actions/ClassificationAction";
import { updateClassification } from "../../actions/ClassificationAction";

const ListClassification = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();

  const columns = [
    { title: "ClassificationId", field: "classificationId", hidden: true },
    { title: "Description", field: "description" },
    { title: "Code", field: "code" },
    { title: "Monto", field: "price", type :'currency' }
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    const listClassification = async () => {
      const response = await getListClassification();
      setData(response.data);
    };
    listClassification();
  }, []);

  const handleRowAdd = (classification, resolve) => {
    const objectClassification = {
      description: classification.description,
      code: classification.code,
      price : parseFloat( classification.price)
    };

    saveClassificationAction(objectClassification).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente la clasificacion";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const listClassification = async () => {
        const response = await getListClassification();
        setData(response.data);
      };
      
      listClassification();
      resolve();
    });
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteClassification(oldData.classificationId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente la clasificacion";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const listClassification = async () => {
        const response = await getListClassification();
        setData(response.data);
      };
      
      listClassification();
      resolve();
    });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    /*if(newData.email === "" || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }*/

    const updateClassificationObject = {
      description: newData.description,
      code: newData.code,
      price : parseFloat( newData.price)
    };

    updateClassification(
      oldData.classificationId,
      updateClassificationObject
    ).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo cambios exitosamente";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const listClassification = async () => {
        const response = await getListClassification();
        setData(response.data);
      };
      
      listClassification();
      resolve();
    });
  };

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: {
              deleteText: "Esta seguro de eliminar la clasificacion?", saveTooltip: "Guardar", cancelTooltip:"Cancelar"    
            },
          },
          header:{ actions : "Aciones"},
        }}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
          paging: true,
          search: true,
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
          searchFieldStyle: {
            backgroundColor: '#fff',
            color: '#000',
            borderRadius:9,
          },
        }}
        title="Edicion de Clasificacion"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        
        style={{
          borderRadius:20, 
          boxShadow: "-5px 5px 6px 3px rgba(0, 0, 0, .75)", 
          backgroundImage: 'linear-gradient(45deg, #2A6049, #c8e6c9, #00afdd',
        }}
      />
    </div>
  );
};

export default ListClassification;
