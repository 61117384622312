import React from 'react';
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CancelIcon from "@material-ui/icons/Cancel";
import PeopleIcon from "@material-ui/icons/People";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import { Tooltip } from '@material-ui/core';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';

export const MenuAdminS = [
    
    {
        title:"Socios",
        icon:
        <Tooltip title="Socios">
        <PeopleIcon/>
        </Tooltip>,
        link: "/associate/list",
    },
    
    {
        title:"Medidores",
        icon:
        <Tooltip title='Medidores'>
        <AvTimerIcon/>
        </Tooltip>,
        link: "/measurer/list",
    },
    {
        title:"Consumo",
        icon:
        <Tooltip title='Consumo'>
        <InvertColorsIcon/>
        </Tooltip>,
        link: "/consumption/list",
    },
    {
        title:"Agregar Aporte",
        icon:
        <Tooltip title="Agregar Aporte">
        <AddShoppingCartIcon/>
        </Tooltip>,
        link: "/contributionAdd/list",
    },
    {
        title:"Agregar Multa",
        icon:
        <Tooltip title="Agregar Multa">
        <CancelIcon/>
        </Tooltip>,
        link: "/fineAdd/list",
        
    },
    {
        title:"Agregar Restante",
        icon:
        <Tooltip title="Agregar Restante">
        <AddShoppingCartIcon/>
        </Tooltip>,
        link: "/pasive/add",
        
    },
    
];


