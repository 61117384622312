import HttpCliente from "../servicios/HttpCliente";

export const saveAssociateAction = (associate) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates", associate)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const saveEvent = (eventData) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/attendance", eventData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const getListEvents = (objectPaged) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/attendance/listEvent", objectPaged).then((response) => {
      resolve(response);
    });
  });
};

export const getListDoneEvents = (objectPaged) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/attendance/listDoneEvent", objectPaged).then((response) => {
      resolve(response);
    });
  });
};

export const getListEventsAssociates = (objectPaged) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/attendance/listEventAssociate", objectPaged).then((response) => {
      resolve(response);
    });
  });
};

export const getListAssociate = (objectPaged) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates/list", objectPaged).then((response) => {
      resolve(response);
    });
  });
};

export const getListAssociateSearch = (objectText) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/associates/search", objectText).then((response) => {
      resolve(response);
    });
  });
};

export const createAttendance = (objectText) => {
  return new Promise((resolve, reject) => {
    HttpCliente.post("/attendance/create", objectText)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const deleteAssociate = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete('/associates/delete/' + id).then((response) => {
      resolve(response);
    });
  });
};

export const deleteEvent = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete('/attendance/delete/' + id).then((response) => {
      resolve(response);
    });
  });
};

export const deleteAttendanceEvent = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.delete('/attendance/deleteAttendance/' + id).then((response) => {
      resolve(response);
    });
  });
};

export const updateAssociate = (id, associate) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put('/associates/' + id, associate).then((response) => {
      resolve(response);
    });
  });
};

export const updateEvent = (id, event) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put('/attendance/' + id, event).then((response) => {
      resolve(response);
    });
  });
};

export const updateAttendanceEvent = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put('/attendance/updateAttendanceEvent/', id).then((response) => {
      resolve(response);
    });
  });
};

export const updateAttendanceDate = (id) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put('/attendance/updateAttendanceDate/', id).then((response) => {
      resolve(response);
    });
  });
};

export const getAllEvents = () => {
  return new Promise((resolve, reject) => {
    HttpCliente.getOnly("/attendance/all").then((response) => {
      resolve(response);
    });
  });
};

export const getAllMeasurer = () => {
  return new Promise((resolve, reject) => {
    HttpCliente.getOnly("/attendance/measurer").then((response) => {
      resolve(response);
    });
  });
};

export const getAllAttendancePdf = (parameters) => {
  return new Promise((resolve) => {
    HttpCliente.getByJasonPdf("/attendance/reportAssociatePdf", parameters).then((response) => {
      resolve(response);
    });
  });
};


export const updateAttendance = (item) => {
  return new Promise((resolve, reject) => {
    HttpCliente.put("/attendance/updateAttendance", item).then((response) => {
      resolve(response);
    })
    .catch((error) => {     
      reject(error.response);
    });
  });
};

export const getReportAssociate = (itemPay) => {
  return new Promise( (resolve, eject) => {
    HttpCliente.getByJasonPdf("/attendance/reportAssociate", itemPay).then( response =>{
          resolve(response);
      });
  })    
}

export const saveFineAttendance = (item) => {
  return new Promise((resolve) => {
    HttpCliente.post("/attendance/addFine", item)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};


export const saveDocument = (item) => {
  return new Promise((resolve) => {
    HttpCliente.post("/associates/createDocument", item)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
