import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
} from "@material-ui/core";
import { useStateValue } from "../../../contexto/store";

import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Styles from "../../Tool/Style";
import BuildIcon from "@material-ui/icons/Build";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuConfig } from "./MenuConfig";
import { MenuAdmin } from "./MenuAdmin";
import { MenuAdminS } from "./MenuAdminS";
import { MenuReader } from "./MenuReader";
import { MenuAssociate } from "./MenuAssociate";
import { MenuReport } from "./MenuReport";
import { MenuAttendance } from "./MenuAttendance";
import { MenuCaja } from "./MenuCaja";
import "../../../App.css";
import BallotIcon from "@material-ui/icons/Ballot";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const CajonIzquierda = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const classes = Styles();

  const [openConfig, setOpenConfig] = useState(false);
  const handleClickConfig = () => {
    setOpenConfig(!openConfig);
  };

  const [openAdmin, setOpenAdmin] = useState(false);
  const handleClickAdmin = () => {
    setOpenAdmin(!openAdmin);
  };

  const [openReport, setOpenReport] = useState(false);
  const handleClickReport = () => {
    setOpenReport(!openReport);
  };

  const [openAttendance, setOpenAttendance] = useState(false);
  const handleClickAttendance = () => {
    setOpenAttendance(!openAttendance);
  };

  const [openCaja, setOpenCaja] = useState(false);
  const handleClickCaja = () => {
    setOpenCaja(!openCaja);
  };

  const [openAssociate, setOpenAssociate] = useState(false);
  const handleClickAssociate = () => {
    setOpenAssociate(!openAssociate);
  };

  const [openReader, setOpenReader] = useState(false);
  const handleClickReader = () => {
    setOpenReader(!openReader);
  };

  const location = useLocation();

  return (
    <>
      {(sesionUsuario.usuario.rolName == "Admin" || sesionUsuario.usuario.rolName == "Secretary") && 
        <>
          <ListItem
            button
            onClick={handleClickConfig}
            className={classes.marginall}
          >
            <Tooltip title="Config">
              <ListItemIcon className={classes.menuItemIcon}>
                <BuildIcon className={classes.menico} color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Config" />
            {openConfig ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openConfig} timeout="auto" unmountOnExit>
            <Divider />
            <List className="SidebarList">
              {/* <MenuIzquierda/>  */}
              {MenuConfig.map((val, key) => {
                return (
                  <ListItem
                    key={key}
                    component={Link}
                    to={val.link}
                    id={location.pathname === val.link ? "active" : ""}
                    className="row"
                  >
                    <ListItemIcon
                      // color="primary"
                      // style={{fontSize:40}}
                      id="icon"
                    >
                      {val.icon}
                      <ListItemText
                        id="title"
                        primary={val.title}
                        // style={{marginLeft:20}}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </>
      }
      {/* dos */}

      {sesionUsuario.usuario.rolName == "Secretary" && 
        <>
          <ListItem
            button
            onClick={handleClickAdmin}
            className={classes.marginall}
          >
            <Tooltip title="Admin">
              <ListItemIcon className={classes.menuItemIcon}>
                <BallotIcon className={classes.menico} color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Admin" />
            {openAdmin ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openAdmin} timeout="auto" unmountOnExit>
            <Divider />
            <List className="SidebarList">
              {/* <MenuIzquierda/>  */}
              {MenuAdminS.map((val, key) => {
                return (
                  <ListItem
                    key={key}
                    id={window.location.pathname == val.link ? "active" : ""}
                    component={Link}
                    to={val.link}
                    className="row"
                  >
                    <ListItemIcon
                      // color="primary"
                      // style={{fontSize:40}}
                      id="icon"
                    >
                      {val.icon}
                      <ListItemText
                        id="title"
                        primary={val.title}
                        // style={{marginLeft:20}}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </>
      }

      {sesionUsuario.usuario.rolName == "Admin"  && 
        <>
          <ListItem
            button
            onClick={handleClickAdmin}
            className={classes.marginall}
          >
            <Tooltip title="Admin">
              <ListItemIcon className={classes.menuItemIcon}>
                <BallotIcon className={classes.menico} color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Admin" />
            {openAdmin ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openAdmin} timeout="auto" unmountOnExit>
            <Divider />
            <List className="SidebarList">
              {/* <MenuIzquierda/>  */}
              {MenuAdmin.map((val, key) => {
                return (
                  <ListItem
                    key={key}
                    id={window.location.pathname == val.link ? "active" : ""}
                    component={Link}
                    to={val.link}
                    className="row"
                  >
                    <ListItemIcon
                      // color="primary"
                      // style={{fontSize:40}}
                      id="icon"
                    >
                      {val.icon}
                      <ListItemText
                        id="title"
                        primary={val.title}
                        // style={{marginLeft:20}}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </>
      }
      {/* reports */}
      {(sesionUsuario.usuario.rolName == "Admin" || sesionUsuario.usuario.rolName == "Secretary") && 
        <>
          <ListItem
            button
            onClick={handleClickReport}
            className={classes.marginall}
          >
            <Tooltip title="Reports">
              <ListItemIcon className={classes.menuItemIcon}>
                <AssignmentIcon className={classes.menico} color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Reports" />
            {openReport ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openReport} timeout="auto" unmountOnExit>
            <Divider />
            <List className="SidebarList">
              {/* <MenuIzquierda/>  */}
              {MenuReport.map((val, key) => {
                return (
                  <ListItem
                    key={key}
                    id={window.location.pathname == val.link ? "active" : ""}
                    component={Link}
                    to={val.link}
                    className="row"
                  >
                    <ListItemIcon
                      // color="primary"
                      // style={{fontSize:40}}
                      id="icon"
                    >
                      {val.icon}
                      <ListItemText
                        id="title"
                        primary={val.title}
                        // style={{marginLeft:20}}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </>
      }
      {/* Caja */}
      {/* Menu Reader */}
      {sesionUsuario.usuario.rolName == "Reader" && 
        <>
          <ListItem
            button
            onClick={handleClickReader}
            className={classes.marginall}
          >
            <Tooltip title="Reader">
              <ListItemIcon className={classes.menuItemIcon}>
                <BallotIcon className={classes.menico} color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Reader" />
            {openReader ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openReader} timeout="auto" unmountOnExit>
            <Divider />
            <List className="SidebarList">
              {MenuReader.map((val, key) => {
                return (
                  <ListItem
                    key={key}
                    id={window.location.pathname == val.link ? "active" : ""}
                    component={Link}
                    to={val.link}
                    className="row"
                  >
                    <ListItemIcon
                      // color="primary"
                      // style={{fontSize:40}}
                      id="icon"
                    >
                      {val.icon}
                      <ListItemText
                        id="title"
                        primary={val.title}
                        // style={{marginLeft:20}}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </>
      }
      {/* Menu Associate */}
      {sesionUsuario.usuario.rolName == "Associate" && 
        <>
          <ListItem
            button
            onClick={handleClickAssociate}
            className={classes.marginall}
          >
            <Tooltip title="Associate">
              <ListItemIcon className={classes.menuItemIcon}>
                <BallotIcon className={classes.menico} color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Menu" />
            {openAssociate ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openAssociate} timeout="auto" unmountOnExit>
            <Divider />
            <List className="SidebarList">
              {/* <MenuIzquierda/>  */}
              {MenuAssociate.map((val, key) => {
                return (
                  <ListItem
                    key={key}
                    id={window.location.pathname == val.link ? "active" : ""}
                    component={Link}
                    to={val.link}
                    className="row"
                  >
                    <ListItemIcon
                      // color="primary"
                      // style={{fontSize:40}}
                      id="icon"
                    >
                      {val.icon}
                      <ListItemText
                        id="title"
                        primary={val.title}
                        // style={{marginLeft:20}}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </>
      }
      {/* Attendance */}
      {(sesionUsuario.usuario.rolName == "Admin" || sesionUsuario.usuario.rolName == "Secretary") && 
        <>
          <ListItem
            button
            onClick={handleClickAttendance}
            className={classes.marginall}
          >
            <Tooltip title="Asistencia">
              <ListItemIcon className={classes.menuItemIcon}>
                <AssignmentIcon className={classes.menico} color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Asistencia" />
            {openAttendance ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse in={openAttendance} timeout="auto" unmountOnExit>
            <Divider />
            <List className="SidebarList">
              {/* <MenuIzquierda/>  */}
              {MenuAttendance.map((val, key) => {
                return (
                  <ListItem
                    key={key}
                    id={window.location.pathname == val.link ? "active" : ""}
                    component={Link}
                    to={val.link}
                    className="row"
                  >
                    <ListItemIcon
                      // color="primary"
                      // style={{fontSize:40}}
                      id="icon"
                    >
                      {val.icon}
                      <ListItemText
                        id="title"
                        primary={val.title}
                        // style={{marginLeft:20}}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </>
      }
    </>
  );
};

export default CajonIzquierda;
