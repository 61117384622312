import React from 'react';
import { SvgIcon } from "@material-ui/core";


export default props => (
    <SvgIcon {...props} viewBox="0 0 1328 1327">
		<path className="cls-1" d="M248,152s1,9,12,9c1,0,10,2,14-7l19-91,11,89s2,9,14,9a15.14,15.14,0,0,0,13-3c4-5,45-95,45-95l-12,86s0,11,12,12c3.72,0,7.43,1.73,13.55-5.24.22-.25,1.84-4,1.84-4L414,22s3-13-10-15c-7-2-27-5-32,1,0,1-44,90-44,90L317,15S315,5,303,5H284a12.24,12.24,0,0,0-12,7L248,144A18.58,18.58,0,0,0,248,152Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M585,147s-1,13,16,15h79s13-2,13-16c0,0,1-15-11-15s-63-1-63-1L638,19s1-15-13-15c0,0-14-2-16,7Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M717.69,146.77s-1,13,16,15h79s13-2,13-16c0,0,1-15-11-15s-63-1-63-1l19-111s1-15-13-15c0,0-14-2-16,7Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M982,34S972,4,921,4c0,0-32-3-59,32,0,2-35,40-12,98,1,2,9,27,54,28,7,0,61-7,69-47,1-2,4-14-7-19,0,0-9-5-15,3-4.22,6.75-16.29,35.61-47,33.59a50.82,50.82,0,0,1-6.62-.87,65.47,65.47,0,0,1-9.1-2.5,17.4,17.4,0,0,1-4.53-2.69,35.06,35.06,0,0,1-2.15-3.08C876.4,115.19,869.09,96.93,880,72c0,0,11-39,49-41,1,0,17,0,23,14l6,14s8,10,18,5c1-1,9-5,9-12S982,34,982,34Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M793.26,202.89s-10-30-61-30c0,0-32-3-59,32,0,2-35,40-12,98,1,2,9,27,54,28,7,0,61-7,69-47,1-2,4-14-7-19,0,0-9-5-15,3-4.23,6.75-16.29,35.61-47,33.59a52.36,52.36,0,0,1-6.62-.87,66,66,0,0,1-9.11-2.5,17.51,17.51,0,0,1-4.52-2.69,33,33,0,0,1-2.15-3.08c-5.18-8.26-12.48-26.52-1.57-51.45,0,0,11-39,49-41,1,0,17,0,23,14l6,14s8,10,18,5c1-1,9-5,9-12S793.26,202.89,793.26,202.89Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M949.7,172.2l-2.94-.51a14,14,0,0,0-16.21,11.38L921.82,233H857.38l7.75-44.34a14,14,0,0,0-11.39-16.21l-2.94-.51a14,14,0,0,0-16.21,11.38l-22.72,130a14,14,0,0,0,11.39,16.21l2.94.51a14,14,0,0,0,16.21-11.38L852.49,261h64.44l-9.1,52.09a14,14,0,0,0,11.38,16.21l2.95.51a14,14,0,0,0,16.2-11.39l22.73-130A14,14,0,0,0,949.7,172.2Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M558,138c-2-10-30-126-30-126s-6-7-11-8h-9s-3.4-.09-3.74,0l-.41.06c-3.25.5-7.87,2.31-11.85,8L418,142s-4,10,7,19a20,20,0,0,0,12,0s3,0,10-9c0-1,16-29,16-29h58l8,31s6,9,12,8c1,0,14-2,17-12C558,150,560,148,558,138ZM477,95l28-49,11,49Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M1106,305.05c-2-10-30-126-30-126s-6-7-11-8h-9s-3.4-.1-3.74-.05a2.9,2.9,0,0,0-.41.06c-3.25.5-7.87,2.31-11.85,8l-74,130s-4,10,7,19a20,20,0,0,0,12,0s3,0,10-9c0-1,16-29,16-29h58l8,31s6,9,12,8c1,0,14-2,17-12C1106,317.05,1108,315.05,1106,305.05Zm-81.27-40.59,28-49,11,49Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M1257,188c-4-3-6-11-36-14-30,0-55,1-55,1h0c-5.76,1-10.59,6.59-11.67,12.67l-22.22,124.54a14.21,14.21,0,0,0,11.49,16.47l3.7.66a14.2,14.2,0,0,0,16.47-11.49l8.72-48.85H1222s29-4,40-33C1265,216,1269,206,1257,188Zm-26,35c-1,2-2,14-25,19h-28.7l7.31-41H1216S1237,201,1231,223Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M1132.52,15.69C1101-4,1047-2,1022,37.7c-29.84,37.18-28.73,87.61,2.46,112.63,33.7,20.19,75.5,12.07,108.17-19.69a70.62,70.62,0,0,0,4.91-6.26C1162,85.53,1162,35.33,1132.52,15.69Zm-19.31,82.18c-11.91,27.38-37.58,42.6-57.33,34s-26.09-37.76-14.18-65.13,37.58-42.61,57.33-34S1125.13,70.49,1113.21,97.87Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M5.21,214.25h50.6V203.83s-.64-6.84,4.77-16.93c.64-.65,6-8.47,10.82-10.1s5.41-1.63,5.41-1.63H91.13s14.64,2.28,18.14,4.89c.63.32,13.68,6.51,19.41,7.16,4.77.65,21,2,28,.33s6.68.32,26.41-8.14c4.77-1.63,5.09-2.93,13.36-3.91H213V112.32H183.09s-4.46-1-5.09-4.23V97h14.32V82.69H178l.32-19.54s-1.6-15.31-16.55-17.59c-5.73-.32-6.05-.32-6.05-.32V31.88h5.73l-.32-11.39h1.28l11.77,4.56S200,27,203.77,27s6.37,1,8-1.95c.32-1.31,1.27-3.91,1-6.19V14.62s0-2.6-1-3.58c-.64-1-1.59-2.6-2.87-2.6H195.5l-11.14,1-10.82,1L162.72,15h-1.59V3.88H132.49V15.6h-1l-11.46-4.88-2.86-.66-10.5-.65-10.5-.65H84.76s-2.22,0-2.86,3.91V23.09a4.83,4.83,0,0,0,3.18,4.23H95l5.72-1L109.9,26l8.91-1,4.78-1.63,7.63-2.61h1.27V31.88h5.73V45.56h-7s-5.41.65-8.27,4.56c-3.18,3.58-5.41,5.86-6.68,10.75,0,2.6-.32.32-.32.32h0v21.5H102v14h14v11.72s0,2.61-3.82,3.91c-2.86,0-45.18.33-45.18.33S17.31,117.21,6.17,165.4A4.17,4.17,0,0,0,5.85,168l-.64,45.92Z" transform="translate(-4.89 -3.5)"/>
		<path className="cls-1" d="M54.06,305.76C54.06,319.7,43,331,29.4,331,12.86,331,4.82,318.38,5,305.76H4.9S4.58,296,10.31,283s19.41-40.71,19.41-40.71,7.63,15.3,13.68,29.63C48.51,284,51.34,288.56,53.15,299c.34,1.9.63,4,.9,6.36v0h0C54.06,305.49,54.06,305.63,54.06,305.76Z" transform="translate(-4.89 -3.5)"/>
		<rect className="cls-1" x="1289.78" y="171.18" width="31.36" height="159.15" rx="15.04" transform="translate(63.51 -239.36) rotate(10.6)"/>
    </SvgIcon>
);
