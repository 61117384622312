import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { saveAssociateAction, saveDocument } from "../../actions/AssociateAction";
import { useStateValue } from "../../contexto/store";
import {
  getListAssociate,
  getListAssociateSearch,
} from "../../actions/AssociateAction";
import { deleteAssociate } from "../../actions/AssociateAction";
import { updateAssociate } from "../../actions/AssociateAction";
import Styles from "../Tool/Style";
import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TablePagination } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getListAssociateExcel } from "../../actions/ReportAction";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import ImageUploader from "react-images-upload";
import CloseIcon from "@material-ui/icons/Close";
import { obtenerDataImagen } from "../../actions/ImagenAction";

const ListAssociate = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [institutionId, setInstitutionId] = useState(
    sesionUsuario.usuario.institutionId
  );
  const [textSearch, setTextSearch] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAssociate, setSelectedAssociate] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [keyImage, setKeyImage] = useState(Date.now());

  const handleOpenDialog = (associate) => {
    setSelectedAssociate(associate);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
    setKeyImage(Date.now()); // Para resetear el ImageUploader
  };

  const subirImagen = (file) => {
    setSelectedImage(file[0]);
    obtenerDataImagen(file[0]).then((respuesta) => {
      setPhoto(respuesta);
    });

  };

  const handleSaveImage = () => {
    if (selectedImage && selectedAssociate) {
      const payload = {
        associateId: selectedAssociate.associateId,
        foto: photo
      }

      saveDocument(payload).then((response) => {
        if (null != textSearch && "" != textSearch) {
          const listAssociates = async () => {
            const objectPagedRequest = {
              pageNumber: pageNumber + 1,
              pageSize: pageSize,
              institutionId: institutionId,
              text: textSearch,
            };
  
            const response = await getListAssociateSearch(objectPagedRequest);
            setPagedResponse(response.data);
          };
          listAssociates();
        } else {
          const listAssociates = async () => {
            const objectPagedRequest = {
              pageNumber: pageNumber + 1,
              pageSize: pageSize,
              institutionId: institutionId,
            };
            const response = await getListAssociate(objectPagedRequest);
            setPagedResponse(response.data);
          };
          listAssociates();
        }
        handleCloseDialog();
      });
    }
  };

  const columns = [
    { title: "associateId", field: "associateId", hidden: true },
    { title: "APELLIDOS", field: "lastName" },
    { title: "NOMBRES", field: "name" },
    { title: "TELEFONO", field: "phoneNumber" },
    { title: "NACIMIENTO", field: "birthday", type: "date", render: (rowData) => formatDate(rowData.birthday) },
    { title: "DOCUMENTO", field: "ci" },
    { title: "DIRECCION ", field: "address" },
    {
      title: "Foto",
      field: "avatar",
      render: (rowData) => (
        <IconButton onClick={() => handleOpenDialog(rowData)}>
          <Avatar
            variant="square"
            className={classes.avatarProducto}
            src={
              `data:image/${rowData.extensionPhoto};base64,${rowData.photo}`
            }
          />
        </IconButton>
      ),
    },
  ];


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (!isNaN(date)) {
      return format(date, "dd/MM/yyyy", { locale: es });
    } else {
      return "";
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const [pagedResponse, setPagedResponse] = useState({
    listRecords: [],
    totalRecords: 0,
    numberPageds: 0,
  });

  const [associateExcel, setAssociateExcel] = useState({
    listAssociate: [],
  });

  useEffect(() => {
    const listAssociates = async () => {
      setInstitutionId(sesionUsuario.usuario.institutionId);
      const objectPagedRequest = {
        pageNumber: 1,
        pageSize: pageSize,
        institutionId: institutionId,
      };

      const response = await getListAssociate(objectPagedRequest);
      setPagedResponse(response.data);

      const responsea = await getListAssociateExcel();
      setAssociateExcel(responsea.data);

    };
    listAssociates();
  }, []);


  const exportToCSV = (fileName) => {

    const list = async () => {
      const responsea = await getListAssociateExcel();
      setAssociateExcel(responsea.data);

    };
    list();

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(associateExcel.listAssociate);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleChangeText = (event) => {
    setTextSearch(event.target.value);
  };

  const handleChangeButton = () => {
    if (null != textSearch && "" != textSearch) {
      setPageNumber(0);
      setPageSize(20);
      const listAssociates = async () => {
        const objectPagedRequest = {
          pageNumber: 1,
          pageSize: 20,
          institutionId: institutionId,
          text: textSearch,
        };

        const response = await getListAssociateSearch(objectPagedRequest);
        setPagedResponse(response.data);
      };
      listAssociates();
    }
  };

  const handleChangeButtonDefault = () => {
    setTextSearch("");
    setPageNumber(0);
    setPageSize(20);
    const listAssociates = async () => {
      const objectPagedRequest = {
        pageNumber: 1,
        pageSize: 20,
        institutionId: institutionId,
      };

      const response = await getListAssociate(objectPagedRequest);
      setPagedResponse(response.data);
    };
    listAssociates();
  };

  const changePaged = (page) => {
    setPageNumber(page);
    if (null != textSearch && "" != textSearch) {
      const listAssociates = async () => {
        const objectPagedRequest = {
          pageNumber: page + 1,
          pageSize: pageSize,
          institutionId: institutionId,
          text: textSearch,
        };

        const response = await getListAssociateSearch(objectPagedRequest);
        setPagedResponse(response.data);
      };
      listAssociates();
    } else {
      const listAssociates = async () => {
        const objectPagedRequest = {
          pageNumber: page + 1,
          pageSize: pageSize,
          institutionId: institutionId,
        };

        const response = await getListAssociate(objectPagedRequest);
        setPagedResponse(response.data);
      };
      listAssociates();
    }
  };

  const changeCantRecords = (value) => {
    setPageSize(value);
    setPageNumber(0);
    const listAssociates = async () => {
      const objectPagedRequest = {
        pageNumber: 1,
        pageSize: value,
        institutionId: institutionId,
      };

      const response = await getListAssociate(objectPagedRequest);
      setPagedResponse(response.data);
    };
    listAssociates();
  };

  const handleRowAdd = (associate, resolve) => {
    const objectAssociate = {
      name: associate.name,
      lastName: associate.lastName,
      phoneNumber: associate.phoneNumber,
      birthday: associate.birthday,
      address: associate.address,
      map: associate.map,
      ci: associate.ci,
      institutionId: institutionId,
    };

    saveAssociateAction(objectAssociate).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente el nuevo socio";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      if (null != textSearch && "" != textSearch) {
        const listAssociates = async () => {
          const objectPagedRequest = {
            pageNumber: pageNumber + 1,
            pageSize: pageSize,
            institutionId: institutionId,
            text: textSearch,
          };

          const response = await getListAssociateSearch(objectPagedRequest);
          setPagedResponse(response.data);
        };
        listAssociates();
      } else {
        const listAssociates = async () => {
          const objectPagedRequest = {
            pageNumber: pageNumber + 1,
            pageSize: pageSize,
            institutionId: institutionId,
          };
          const response = await getListAssociate(objectPagedRequest);
          setPagedResponse(response.data);
        };
        listAssociates();
      }

      resolve();
    });
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteAssociate(oldData.associateId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente el socio";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      if (null != textSearch && "" != textSearch) {
        const listAssociates = async () => {
          const objectPagedRequest = {
            pageNumber: pageNumber + 1,
            pageSize: pageSize,
            institutionId: institutionId,
            text: textSearch,
          };

          const response = await getListAssociateSearch(objectPagedRequest);
          setPagedResponse(response.data);
        };
        listAssociates();
      } else {
        const listAssociates = async () => {
          const objectPagedRequest = {
            pageNumber: pageNumber + 1,
            pageSize: pageSize,
            institutionId: institutionId,
          };
          const response = await getListAssociate(objectPagedRequest);
          setPagedResponse(response.data);
        };
        listAssociates();
      }

      resolve();
    });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {

    const updateAssociateObject = {
      associateId: newData.associateId,
      name: newData.name,
      lastName: newData.lastName,
      phoneNumber: newData.phoneNumber,
      birthday: newData.birthday,
      address: newData.address,
      map: newData.map,
      ci: newData.ci,
    };

    updateAssociate(oldData.associateId, updateAssociateObject).then(
      (response) => {
        let message = "";

        if (response.status === 200) {
          message += "Se guardo cambios exitosamente";
        } else {
          message += "Errores :" + Object.keys(response.data.errors);
        }

        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: message,
          },
        });

        if (null != textSearch && "" != textSearch) {
          const listAssociates = async () => {
            const objectPagedRequest = {
              pageNumber: pageNumber + 1,
              pageSize: pageSize,
              institutionId: institutionId,
              text: textSearch,
            };

            const response = await getListAssociateSearch(objectPagedRequest);
            setPagedResponse(response.data);
          };
          listAssociates();
        } else {
          const listAssociates = async () => {
            const objectPagedRequest = {
              pageNumber: pageNumber + 1,
              pageSize: pageSize,
              institutionId: institutionId,
            };
            const response = await getListAssociate(objectPagedRequest);
            setPagedResponse(response.data);
          };
          listAssociates();
        }
        resolve();
      }
    );
  };
  const classes = Styles();
  return (
    <div className={classes.tab}>
      <Tooltip title="Ingresa nombre o apellido">
        <TextField
          id="standard-search"
          label="Buscar "
          type="search"
          value={textSearch}
          onChange={handleChangeText}
        />
      </Tooltip>

      <Tooltip title="Busca los datos ingresados">
        <Button
          id="btnSearch"
          variant="outlined"
          size="large"
          color="primary"
          onClick={handleChangeButton}
        >
          Buscar
        </Button>
      </Tooltip>
      <Tooltip title="Muestra el listado">
        <Button
          id="btnDefault"
          variant="outlined"
          size="large"
          color="primary"
          onClick={handleChangeButtonDefault}
        >
          Listado
        </Button>
      </Tooltip>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <MaterialTable
          localization={{
            body: {
              editRow: {
                deleteText: "Esta seguro de eliminar el socio?",
                cancelTooltip: "Cancelar",
                saveTooltip: "Guardar",
              },
            },
            toolbar: {
              exportTitle: "Exportar",
              exportName: "Exportar a excel"
            },
          }}
          options={{
            pageSize: 20,
            exportButton: true,
            exportCsv: () => {
              exportToCSV("Lista Socios");
            },
            actionsColumnIndex: -1,
            padding: "dense",
            paging: true,
            search: false,
            headerStyle: {
              backgroundColor: "#e8f5e9",
              color: "#000",
            },
          }}
          title="SOCIOS"
          columns={columns}
          data={pagedResponse.listRecords}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                handleRowAdd(newData, resolve);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                handleRowUpdate(newData, oldData, resolve);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                handleRowDelete(oldData, resolve);
              }),
          }}
          components={{
            Pagination: (props) => (
              <TablePagination
                rowsPerPageOptions={[20, 600]}
                rowsPerPage={pageSize}
                count={pagedResponse.totalRecords}
                page={pageNumber}
                onChangePage={(e, page) => {
                  changePaged(page);
                }}
                onChangeRowsPerPage={(event) => {
                  props.onChangeRowsPerPage(event);
                  changeCantRecords(event.target.value);
                }}
                labelRowsPerPage="Socios por pagina"
                nextIconButtonText="Siguiente"
                backIconButtonText="Anterior"
              />
            ),
          }}
          style={{
            borderRadius: 20,
            boxShadow: "-5px 5px 6px 3px rgba(0, 0, 0, .75)",
            backgroundImage: "linear-gradient(45deg, #5E8F72, #c8e6c9, #00afdd",
          }}
        />
      </MuiPickersUtilsProvider>

      <Dialog
        open={openDialog}
        fullWidth={true}
        onClose={handleCloseDialog}>
        <DialogTitle>
          Actualizar Foto
          <IconButton aria-label="close" onClick={handleCloseDialog} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Avatar
            alt={selectedAssociate ? selectedAssociate.name : ""}
            src={selectedImage ? URL.createObjectURL(selectedImage) : (selectedAssociate ? `data:image/${selectedAssociate.extensionPhoto};base64,${selectedAssociate.photo}` : "")}
            style={{ width: 200, height: 200, marginBottom: 20 }}
          />
          <ImageUploader
            withIcon={true}
            singleImage={true}
            key={keyImage}
            buttonText="Buscar Imagen"
            imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
            maxFileSize={10485760} // 10MB
            onChange={subirImagen}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSaveImage} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default ListAssociate;
