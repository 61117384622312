import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { getListContribution } from "../../actions/ContributionAction";
import { useStateValue } from "../../contexto/store";
import { saveContribution } from "../../actions/ContributionAction";
import { deleteContribution } from "../../actions/ContributionAction";
import { updateContribution } from "../../actions/ContributionAction";

const ListContribution = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();

  const columns = [
    { title: "ContributionId", field: "contributionId", hidden: true },
    { title: "Nombre Aporte", field: "name" },
    { title: "Descripcion", field: "description" },
    { title: "Monto", field: "price" , type : 'currency'},
    { title: "Automatico?", field: "automatic" , type : 'boolean'  },
    { title: "Es Propio Institucion?", field: "isOwner" , type : 'boolean'  }
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    const list = async () => {
      const response = await getListContribution();
      setData(response.data);
    };
    list();
  }, []);

  const handleRowAdd = (contribution, resolve) => {
    const objectContribution = {
      name: contribution.name,
      description: contribution.description,
      price : parseFloat(contribution.price),
      automatic : contribution.automatic,
      isOwner : contribution.isOwner
    };

    saveContribution(objectContribution).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo exitosamente el aporte";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListContribution();
        setData(response.data);
      };
      list();
      resolve();
    });
  };

  const handleRowDelete = (oldData, resolve) => {
    deleteContribution(oldData.contributionId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se elimino exitosamente el aporte";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListContribution();
        setData(response.data);
      };
      list();
      resolve();
    });
  };

  const handleRowUpdate = (newData, oldData, resolve) => {
    /*if(newData.email === "" || validateEmail(newData.email) === false){
      errorList.push("Please enter a valid email")
    }*/

    const contributionObject = {
      name: newData.name,
      description: newData.description,
      price : parseFloat(newData.price),
      automatic : newData.automatic,
      isOwner : newData.isOwner
    };

    updateContribution(
      oldData.contributionId,
      contributionObject
    ).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardo cambios exitosamente";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      const list = async () => {
        const response = await getListContribution();
        setData(response.data);
      };
      list();
      resolve();
    });
  };

  return (
    <div>
      <MaterialTable
        localization={{
          body: {
            editRow: {
              deleteText: "Esta seguro de eliminar el aporte?",
            },
          },
        }}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
          paging: true,
          search: true,
          headerStyle: {
            backgroundColor: "#e8f5e9",
            color: "#000",
          },
          searchFieldStyle: {
            backgroundColor: '#fff',
            color: '#000',
            borderRadius:9,
          },
        }}
        title="Edicion de Aportes"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              handleRowUpdate(newData, oldData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
        style={{
          borderRadius:20, 
          boxShadow: '-5px 5px 6px 3px rgba(0, 0, 0, .75)',
          backgroundImage: 'linear-gradient(45deg, #2A6049, #c8e6c9, #00afdd',
        }}
      />
    </div>
  );
};

export default ListContribution;
