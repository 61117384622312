import React from "react";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { format } from "date-fns";
import { Grid } from "@material-ui/core";
import person from "../../person.jpg";
import ListTableMeasurer from "./ListTableMeasurer";
import Styles from "../Tool/Style";

const MeasurerCard = ({ associate, datac }) => {
  const classes = Styles();

  return (
    <Card className={classes.rootCar} >
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarC}>
            {associate.lastName.substring(0, 1)}
          </Avatar>
        }
        titleTypographyProps={{ variant: 'h6' }}
        title={associate.lastName + " " + associate.name}
        subheader={
          "Ingreso : " + format(new Date(associate.dateCreate), "dd/MM/yyyy")
        }
      />

      <Grid container spacing={1}>
        <Grid item xs={12} md={3} align={"center"}>
          <Avatar
            variant="square"
            className={classes.avatarMeasure}
            src={
              `data:image/${associate.extensionPhoto};base64,${associate.photo}`
            }
          />
          <Typography component="h2" variant="h6">
            {" "}
            Telefono : {associate.phoneNumber}{" "}
          </Typography>
          <Typography component="h2" variant="h6">
            {" "}
            Ci : {associate.ci}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <ListTableMeasurer value={associate.associateId} datac={datac} key={associate.associateId} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurerCard;
