import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { getAllAttendancePdf, getListEvents, getReportAssociate, saveFineAttendance } from '../../actions/AssociateAction';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getListFine } from '../../actions/FineAction';
import { useStateValue } from '../../contexto/store';

const Administration = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [pagedResponse, setPagedResponse] = useState({
    listRecords: [],
    totalRecords: 0,
    numberPageds: 0,
  });
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [openAbsenceDialog, setOpenAbsenceDialog] = useState(false);
  const [openDelayDialog, setOpenDelayDialog] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [reportType, setReportType] = useState('');
  const [dateSelect, setDateSelect] = useState(new Date());
  const [fine, setFine] = useState('');
  const [names, setNames] = useState([]);

  const fetchEvents = async (pageNumber, pageSize, searchQuery) => {
    const objectPagedRequest = {
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
      searchQuery: searchQuery,
    };
    const response = await getListEvents(objectPagedRequest);
    setPagedResponse(response.data);
  };

  useEffect(() => {
    fetchEvents(page, rowsPerPage, searchQuery);
  }, [page, rowsPerPage, searchQuery]);

  const handlePrint = (eventId) => {
    setSelectedEventId(eventId);
    setOpenReportDialog(true);
  };

  const handleSendAbsence = (eventId) => {
    
    const list = async () => {
      const response = await getListFine();
      setNames(response.data);
    };
    list();
    setSelectedEventId(eventId);
    setOpenAbsenceDialog(true);
  };

  const handleSendDelay = (eventId) => {
   
    const list = async () => {
      const response = await getListFine();
      setNames(response.data);
    };
    list();
    setSelectedEventId(eventId);
    setOpenDelayDialog(true);
   
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0); // Reset to the first page on new search
    fetchEvents(0, rowsPerPage, searchQuery);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
    setReportType('');
  };

  const handleCloseAbsenceDialog = () => {
    setOpenAbsenceDialog(false);
    setFine('');
  };

  const handleCloseDelayDialog = () => {
    setOpenDelayDialog(false);
    setFine('');
  };

  const handleGenerateReport = async () => {
    const item = {
      eventId: selectedEventId,
      reportType: reportType,
    };
    const listExcel = async (dataItem) => {
      const res = await getReportAssociate(dataItem);
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };
    listExcel(item);
    handleCloseReportDialog();
  };

  const handleSendAbsenceReport = () => {
    setOpenAbsenceDialog(false);
    
    const payload = {
      DateConsumption: dateSelect,
      fineId: fine,
      eventId: selectedEventId,
      isLate: false,
    }

    saveFineAttendance(payload).then((response) => {
      let message = "";

      if (response.status === 200) {
        fetchEvents(0, rowsPerPage, searchQuery);
        message += "Se guardo exitosamente";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
      setFine('');
    });
  };

  const handleSendDelayReport = () => {
    setOpenDelayDialog(false);    
    const payload = {
      DateConsumption: dateSelect,
      fineId: fine,
      eventId: selectedEventId,
      isLate : true,
    }

    saveFineAttendance(payload).then((response) => {
      let message = "";

      if (response.status === 200) {
        fetchEvents(0, rowsPerPage, searchQuery);
        message += "Se guardo exitosamente";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
      setFine('');
    });
  };

  const handleChangeFine = (event) => {
    setFine(event.target.value);
  };

  const handleGeneratePdfReport = (eventId) => {
    const listPdf = async () => {
      const objectRequest = {
        eventId: eventId,
      };
      const res = await getAllAttendancePdf(objectRequest);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    };
    listPdf();
  };
  return (
    <Container style={{ marginTop: '50px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <TextField
          label="Buscar por nombre"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Buscar
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre evento</TableCell>
              <TableCell>Fecha evento</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedResponse.listRecords.map((event, index) => (
              <TableRow key={index}>
                <TableCell>{event.name}</TableCell>
                <TableCell>{event.dateEvent}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePrint(event.eventId)}
                    style={{ marginRight: '10px' }}
                  >
                    Reporte Excel
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleGeneratePdfReport(event.eventId)}
                    style={{ marginRight: '10px' }}
                  >
                    Reporte PDF
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSendAbsence(event.eventId)}
                    style={{ marginRight: '10px' }}
                  >
                    Enviar Faltas
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSendDelay(event.eventId)}
                  >
                    Enviar Retrasos
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={pagedResponse.totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página"
          style={{ backgroundColor: '#f5f5f5', color: '#333' }}
        />
      </TableContainer>

      {/* Dialogo para generar reporte */}
      <Dialog open={openReportDialog} onClose={handleCloseReportDialog}>
        <DialogTitle>Generar Reporte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione el tipo de reporte para el evento.
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel>Tipo de Reporte</InputLabel>
            <Select
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Permiso">Permisos</MenuItem>
              <MenuItem value="Falta">Faltas</MenuItem>
              <MenuItem value="Presente">Presentes</MenuItem>
              <MenuItem value="Retraso">Retrasados</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReportDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleGenerateReport} color="primary">
            Generar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para enviar faltas */}
      <Dialog open={openAbsenceDialog} onClose={handleCloseAbsenceDialog}>
        <DialogTitle>AGREGAR MULTAS POR FALTA</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Revisar muy bien los socios que faltaron antes de realizar este operacion.
            Seleccione la fecha para el mes de consumo y multa correspondiente.
          </DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateSelect}
              onChange={setDateSelect}
              margin="normal"
              id="fecha-publicacion-id"
              label="Seleccione mes de consumo"
              format="MM/yyyy"
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              cancelLabel="Cancelar"
              okLabel="Seleccionar"
              views={["month", "year"]}
            />
          </MuiPickersUtilsProvider>

          <FormControl fullWidth>
            <InputLabel>Seleccione multas</InputLabel>
            <Select
              value={fine}
              onChange={handleChangeFine}
            >
              {names.map((name) => (
                <MenuItem key={name.fineId} value={name.fineId}>
                  {name.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAbsenceDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSendAbsenceReport} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para enviar retrasos */}
      <Dialog open={openDelayDialog} onClose={handleCloseDelayDialog}>
        <DialogTitle>AGREGAR MULTAS POR RETRASOS</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Revisar muy bien los socios que llegaron tarde antes de realizar esta operación.
            Seleccione la fecha para el mes de consumo y multa correspondiente.
          </DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              value={dateSelect}
              onChange={setDateSelect}
              margin="normal"
              id="fecha-publicacion-id"
              label="Seleccione mes de consumo"
              format="MM/yyyy"
              fullWidth
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              cancelLabel="Cancelar"
              okLabel="Seleccionar"
              views={["month", "year"]}
            />
          </MuiPickersUtilsProvider>

          <FormControl fullWidth>
            <InputLabel>Seleccione multas</InputLabel>
            <Select
              value={fine}
              onChange={handleChangeFine}
            >
              {names.map((name) => (
                <MenuItem key={name.fineId} value={name.fineId}>
                  {name.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelayDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSendDelayReport} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Administration;
