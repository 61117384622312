import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Badge
} from '@material-ui/core';
import { deleteAttendanceEvent, getAllEvents, getListEventsAssociates, updateAttendance, updateAttendanceDate, updateAttendanceEvent } from '../../actions/AssociateAction';
import { useStateValue } from '../../contexto/store';
import { Delete, Edit, Face, Person } from '@material-ui/icons';

const BarcodeSearch = () => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [description, setDescription] = useState('');
  const [events, setEvents] = useState([]);
  const [searchCode, setSearchCode] = useState('');
  const [eventO, setEventO] = useState('');
  const [only, setOnly] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [colorRow, setColorRow] = useState('green');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isSalida, setIsSalida] = useState(false);
  const [pagedResponse, setPagedResponse] = useState({
    listRecords: [],
    totalRecords: 0,
    numberPageds: 0,
    percentage: 0,
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [licenseDialogOpen, setLicenseDialogOpen] = useState(false);
  const [dateDialogOpen, setDateDialogOpen] = useState(false);
  const [dateDialogData, setDateDialogData] = useState('');
  const [oldDialogOpen, setOldDialogOpen] = useState(false);
  const [licenseItemId, setLicenseItemId] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getAllEvents();
        setEvents(response.data);
      } catch (error) {
        console.error('Error al obtener eventos:', error);
      }
    };

    fetchEvents();
  }, []);

  const fetchMembers = async (eventId) => {
    const objectPagedRequest = {
      pageNumber: page + 1,
      pageSize: rowsPerPage,
      eventId: eventId || '12345678-1234-1234-1234-1234567890ab',
      codeMeasure: searchCode,
    };

    try {
      const response = await getListEventsAssociates(objectPagedRequest);
      setPagedResponse(response.data);
    } catch (error) {
      console.error('Error al obtener miembros:', error);
    }
  };

  useEffect(() => {
    if (eventO) {
      fetchMembers(eventO);
    }
  }, [page, rowsPerPage, eventO]);

  const handleSearch = () => {
    const payload = {
      eventId: eventO,
      isLeft: isSalida,
      code: searchTerm,
      codeMeasure: searchCode,
    };

    updateAttendance(payload)
      .then((response) => {
        let message = "";

        if (response.status === 200) {
          if (response.data === 'Add') {
            message += "Se registró código: " + searchTerm;
            setColorRow('green');
          }
          else {
            setColorRow('red');
            message += "Ya se registro este código: " + searchTerm;
          }
          fetchMembers(eventO);

          // Destacar la fila
          setHighlightedRow(searchTerm);
          setTimeout(() => setHighlightedRow(null), 5000); // Desactivar después de 5 segundos
          setSearchTerm('');
        } else {
          message += "No está registrado para este evento: " + searchTerm;
        }

        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: message,
          },
        });

      }).catch((error) => {
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "NO SE REGISTRÓ PARA ESTE EVENTO: " + searchTerm,
          },
        });
        setSearchTerm('');
      });
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const registerButton = () => {
    handleSearch();
  };
  const searchButtonCode = () => {
    fetchMembers(eventO);
  };

  const cleanButtonCode = async () => {
    setSearchCode('');
    const objectPagedRequest = {
      pageNumber: page + 1,
      pageSize: rowsPerPage,
      eventId: eventO || '12345678-1234-1234-1234-1234567890ab',
      codeMeasure: '',
    };

    try {
      const response = await getListEventsAssociates(objectPagedRequest);
      setPagedResponse(response.data);
    } catch (error) {
      console.error('Error al obtener miembros:', error);
    }
  };

  const handleEventChange = (event) => {
    const selectedEventId = event.target.value;
    setEventO(selectedEventId);
    const selectedEvent = events.find(e => e.eventId === selectedEventId);
    setOnly(selectedEvent.onlyEntry);
    setPage(0);
  };

  const handleEdit = (id) => {
    setLicenseItemId(id);
    setLicenseDialogOpen(true);
  };

  const handleEditDate = (id, date) => {
    setLicenseItemId(id);
    setDateDialogOpen(true);
    setDateDialogData(date);
  };

  const handleOld = (id) => {
    setLicenseItemId(id);
    setOldDialogOpen(true);
  };

  const isOld = (date) => {
    const [day, month, year] = date.split('/').map(Number);
    const birthDate = new Date(year, month - 1, day);

    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= 60;
  };

  const handleDelete = (id) => {
    setDeleteItemId(id);
    setDeleteDialogOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleConfirmDelete = () => {
    deleteAttendanceEvent(deleteItemId).then(() => {
      fetchMembers(eventO);
    });
    setDeleteDialogOpen(false);
  };

  const handleConfirmLicense = () => {
    const payload = {
      attendanceId: licenseItemId,
      description: description
    };
    updateAttendanceEvent(payload).then(() => {
      fetchMembers(eventO);
    })
    setLicenseDialogOpen(false);
    setDescription('');
  };

  const handleConfirmDate = () => {
    const payload = {
      attendanceId: licenseItemId,
      dateData: dateDialogData
    };
    updateAttendanceDate(payload).then(() => {
      fetchMembers(eventO);
    })
    setDateDialogOpen(false);
  };

  const handleConfirmOldLicense = () => {
    const payload = {
      attendanceId: licenseItemId,
      description: "Tercera Edad"
    };
    updateAttendanceEvent(payload).then(() => {
      fetchMembers(eventO);
    })
    setOldDialogOpen(false);
    setDescription('');
  };

  return (
    <Container style={{ marginTop: '50px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: 5, margin: 'auto' }}>
            <Typography variant="h4">Seleccione evento</Typography>
            <FormControl variant="outlined" style={{ marginTop: 16 }}>
              <InputLabel id="event-select-label">Evento</InputLabel>
              <Grid container alignItems="center">
                <Grid item>
                  <Select
                    labelId="event-select-label"
                    value={eventO}
                    onChange={handleEventChange}
                    label="Evento"
                  >
                    {events.map((event) => (
                      <MenuItem
                        key={event.eventId}
                        value={event.eventId}
                      >
                        {event.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <Badge
                    color="primary"
                    badgeContent={pagedResponse.totalRecords}
                    max={9999}
                    style={{ marginLeft: 16 }}
                  >
                    <Person color="primary" fontSize="large" />
                  </Badge>
                </Grid>
                <Grid item>
                  <Typography variant="h6" style={{ marginLeft: 16 }}>
                    Ingreso un {pagedResponse.percentage.toFixed(2)}%
                  </Typography>
                </Grid>
              </Grid>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>

      <Paper style={{ padding: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Registrar por código de barras
        </Typography>
        <TextField
          label="Código de Barras"
          variant="outlined"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          onKeyPress={handleKeyPress}
          style={{ marginBottom: '20px', paddingRight: '10px' }}
        />
        {!only &&
          <FormControlLabel
            control={
              <Checkbox
                checked={isSalida}
                onChange={(event) => setIsSalida(event.target.checked)}
                color="primary"
              />
            }
            label="Es salida?"
          />
        }



        <Button type="submit" onClick={registerButton} variant="contained" color="primary" size="large">
          Registrar
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Typography variant="h4" component="h1" gutterBottom>
          Socios asignados
        </Typography>
        <TextField
          label="Buscar por código"
          variant="outlined"
          value={searchCode}
          onChange={(event) => setSearchCode(event.target.value)}
          style={{ marginBottom: '20px', paddingRight: '50px' }}
        />
        <Button type="submit" onClick={searchButtonCode} variant="contained" color="primary" size="large">
          Buscar codigo
        </Button>
        <Button type="submit" onClick={cleanButtonCode} variant="contained" color="primary" size="large">
          Limpiar
        </Button>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Socio</TableCell>
              <TableCell>Código medidor</TableCell>
              <TableCell>Ingreso</TableCell>
              {!only &&
                <TableCell>Salida</TableCell>
              }

              <TableCell>Permiso</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedResponse.listRecords.map((event, index) => (
              <TableRow
                key={index}
                style={{ backgroundColor: event.code === highlightedRow ? colorRow : 'inherit' }}
              >
                <TableCell>{event.associate}</TableCell>
                <TableCell>{event.code}</TableCell>
                <TableCell>{event.entry}
                  <IconButton onClick={() => handleEditDate(event.id, 'entry')}>
                    <Edit />
                  </IconButton>
                </TableCell>
                {!event.onlyEntry && <TableCell>{event.left}
                  <IconButton onClick={() => handleEditDate(event.id, 'left')}>
                    <Edit />
                  </IconButton></TableCell>}

                <TableCell>{event.permission}</TableCell>
                <TableCell>
                  {isOld(event.dateBirthdate) &&
                    <IconButton onClick={() => handleOld(event.id)}>
                      <Face />
                    </IconButton>
                  }

                  {!isOld(event.dateBirthdate) &&
                    <IconButton onClick={() => handleEdit(event.id)}>
                      <Edit />
                    </IconButton>
                  }

                  <IconButton onClick={() => handleDelete(event.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={pagedResponse.totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página"
          style={{ backgroundColor: '#f5f5f5', color: '#333' }}
        />
      </TableContainer>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro que deseas eliminar este código de este evento?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={licenseDialogOpen}
        onClose={() => setLicenseDialogOpen(false)}
        aria-labelledby="license-dialog-title"
        aria-describedby="license-dialog-description"
      >
        <DialogTitle id="license-dialog-title">Confirmar Poner Licencia</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro que deseas poner licencia a este código?
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Motivo de la licencia"
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLicenseDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmLicense} color="primary" autoFocus>
            Poner Licencia
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dateDialogOpen}
        onClose={() => setDateDialogOpen(false)}
        aria-labelledby="license-dialog-title"
        aria-describedby="license-dialog-description"
      >
        <DialogTitle id="license-dialog-title">Confirmar resetear</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro de resetear la fecha y hora?
          </Typography>
         
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDateDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDate} color="primary" autoFocus>
            Resetear
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={oldDialogOpen}
        onClose={() => setOldDialogOpen(false)}
        aria-labelledby="license-dialog-title"
        aria-describedby="license-dialog-description"
      >
        <DialogTitle id="license-dialog-title">Confirmar tercera edad</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro que es tercera edad?
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOldDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmOldLicense} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


    </Container>
  );
};

export default BarcodeSearch;
