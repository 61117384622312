import React from "react";
import { Grid, Button, CardActions } from "@material-ui/core";
import Card from "@material-ui/core/Card";

import Styles from "../Tool/Style";
import { cleanContribution, cleanFine } from "../../actions/ConsumptionAction";
import { useStateValue } from "../../contexto/store";

const Clean = () => {
    const [{ sesionUsuario }, dispatch] = useStateValue();
    const classes = Styles();

    const cleanFines = () => {
        cleanFine().then((response) => {
            let message = "";

            if (response.status === 200) {
                message += "Se limpio exitosamente";
            } else {
                message += "Fallo en la limpieza de multas";
            }

            dispatch({
                type: "OPEN_SNACKBAR",
                openMensaje: {
                    open: true,
                    mensaje: message,
                },
            });
        });
    };

    const cleanContributions = () => {
        cleanContribution().then((response) => {
            let message = "";

            if (response.status === 200) {
                message += "Se limpio exitosamente";
            } else {
                message += "Fallo en la limpieza de aportes";
            }

            dispatch({
                type: "OPEN_SNACKBAR",
                openMensaje: {
                    open: true,
                    mensaje: message,
                },
            });
        });
    };

    return (
        <Grid container spacing={2} justify="center" className={classes.griContainer}>
            <Grid item xs={12} sm={6} md={4}>
                <Card variant="outlined">
                    <CardActions>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={cleanFines}
                        >
                            Limpiar multas
                        </Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Card variant="outlined">
                    <CardActions>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={cleanContributions}
                        >
                            Limpiar aportes
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>

    );
};

export default Clean;