import React, { useState, useEffect } from "react";
import { ThemeProvider as MuithemeProvider} from "@material-ui/core/styles";
import { Grid, Snackbar } from "@material-ui/core";
import theme from "./theme/theme";
import RegistrarUsuario from "./componentes/seguridad/RegistrarUsuario";
import Login from "./componentes/seguridad/Login";
import PerfilUsuario from "./componentes/seguridad/PerfilUsuario";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { useStateValue } from "./contexto/store";
import { obtenerUsuarioActual } from "./actions/UsuarioAction";
import RutaSegura from "./componentes/navegacion/RutaSegura";

import NewAssociate from "./componentes/associate/NewAssociate";
import NewRoles from "./componentes/Roles/NewRoles";
import NewEntry from "./componentes/Entry/NewEntry";
import NewExpense from "./componentes/Expense/NewExpense";
import NewNotice from "./componentes/Notice/NewNotice";
import TableAssociate from "./componentes/associate/ListAssociate";
import TableInstitution from "./componentes/institution/ListInstitution";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppNavbar from "./componentes/navegacion/AppNavbar";
import TableMeasurer from "./componentes/Measurer/ListCardAssociate";
import TableClassification from "./componentes/Classification/ListClassification";
import TableEntry from "./componentes/Entry/EntryList";
import TableContribution from "./componentes/Contribution/ListContribution";
import TableFine from "./componentes/fine/ListFine";
import TableConsumption from "./componentes/consumption/ListCardMeasurer";
import RoleReaderList from "./componentes/RoleReader/ListCardMeasurer";

import TableContributionAdd from "./componentes/Contribution/ListCardMeasurer";
import TableFineAdd from "./componentes/fine/ListCardMeasurer";
import TableDiscount from "./componentes/Discount/ListCardMeasurer";
import Paytoday from "./componentes/Report/Paytoday";
import MeasurerReader from "./componentes/Report/MeasurerReader";
import MainTransferred from "./componentes/Transferred/Index";
import Index from "./componentes/RoleAssociate/Index";
import IndexPay from "./componentes/RoleAssociate/IndexPay";
import MeasurerCut from "./componentes/Report/MeasurerCut";
import MeasurerPay from "./componentes/Report/MeasurerPay";
import TransferAssociate from "./componentes/Report/TransferAssociate";
import Measures from "./componentes/Report/Measurers";
import Charges from "./componentes/Report/Charges";
import Consumptions from "./componentes/Report/Consumptions";
import Add from "./componentes/Pasive/Add";
import Clean from "./componentes/consumption/Clean"
import BarcodeSearch from "./componentes/attendance/BarcodeReader"
import EventRegister from "./componentes/attendance/EventRegister"
import AttendanceRegister from "./componentes/attendance/AttendanceRegister"
import Administration from "./componentes/attendance/Administration";
import LastAttendance from "./componentes/attendance/LastAttendance";




function App() {
  const [{ openSnackbar }, dispatch] = useStateValue();

  const [iniciaApp, setIniciaApp] = useState(false);

  useEffect(() => {
    if (!iniciaApp) {
      obtenerUsuarioActual(dispatch)
        .then((response) => {
          setIniciaApp(true);
        })
        .catch((error) => {
          setIniciaApp(true);
        });
    }
  }, [iniciaApp]);

  return iniciaApp === false ? null : (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar ? openSnackbar.open : false}
        autoHideDuration={3000}
        ContentProps={{ "aria-describedby": "message-id" }}
        message={
          <span id="message-id">
            {openSnackbar ? openSnackbar.mensaje : ""}
          </span>
        }
        onClose={() =>
          dispatch({
            type: "OPEN_SNACKBAR",
            openMensaje: {
              open: false,
              mensaje: "",
            },
          })
        }
      ></Snackbar>
      <Router>
      
        <MuithemeProvider theme={theme}>
        <Route path="/auth/login" component={Login} />
          <Grid container>
            <Grid item>
              <AppNavbar />
            </Grid>
            <Grid item>
              <CssBaseline />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={10} style={{paddingLeft:5, paddingRight:5, marginTop:70}} >
              <Switch>
                
                <Route exact path="/auth/registrar" component={RegistrarUsuario} />
                
                <RutaSegura exact path="/auth/perfil" component={PerfilUsuario} />
                <RutaSegura exact path="/" component={PerfilUsuario} />
                <RutaSegura exact path="/associate/new" component={NewAssociate} />
                <RutaSegura exact path="/associate/list" component={TableAssociate} />
                <RutaSegura exact path="/measurer/list" component={TableMeasurer} />
                <RutaSegura exact path="/roles/new" component={NewRoles} />
                <RutaSegura exact path="/entry/new" component={NewEntry} />
                <RutaSegura exact path="/expense/new" component={NewExpense} />
                <RutaSegura exact path="/reports" component={TableEntry} />
                <RutaSegura exact path="/notice/new" component={NewNotice} />
                <RutaSegura exact path="/institution/list" component={TableInstitution} />
                <RutaSegura exact path="/classification/list" component={TableClassification} />
                <RutaSegura exact path="/contribution/list" component={TableContribution} />
                <RutaSegura exact path="/fine/list" component={TableFine} />
                <RutaSegura exact path="/consumption/list" component={TableConsumption} />
                <RutaSegura exact path="/contributionAdd/list" component={TableContributionAdd} />
                <RutaSegura exact path="/fineAdd/list" component={TableFineAdd} />
                <RutaSegura exact path="/discount/list" component={TableDiscount} />
                <RutaSegura exact path="/roleReader/list" component={RoleReaderList} />
                <RutaSegura exact path="/roleAssociate/list" component={Index} />
                <RutaSegura exact path="/roleAssociate/listPays" component={IndexPay} />
                <RutaSegura exact path="/reports/paytoday" component={Paytoday} />
                <RutaSegura exact path="/reports/measurer" component={MeasurerReader} />
                <RutaSegura exact path="/reports/measurerCut" component={MeasurerCut} />
                <RutaSegura exact path="/transferred/register" component={MainTransferred} />
                <RutaSegura exact path="/reports/measurerPay" component={MeasurerPay} />
                <RutaSegura exact path="/reports/transferAssociate" component={TransferAssociate} />
                <RutaSegura exact path="/reports/measures" component={Measures} />
                <RutaSegura exact path="/reports/charges" component={Charges} />
                <RutaSegura exact path="/reports/consumptions" component={Consumptions} /> 
                <RutaSegura exact path="/pasive/add" component={Add} />
                <RutaSegura exact path="/clean/error" component={Clean} />            
                <RutaSegura exact path="/attendance/events" component={EventRegister} />            
                <RutaSegura exact path="/attendance/register" component={AttendanceRegister} />            
                <RutaSegura exact path="/attendance/control" component={BarcodeSearch} />            
                <RutaSegura exact path="/attendance/admin" component={Administration} />            
                <RutaSegura exact path="/attendance/last" component={LastAttendance} />            
              </Switch>
            </Grid>
          </Grid>    
        </MuithemeProvider>
      </Router>
    </>
  );
};

export default App;
