import axios from "axios";

// axios.defaults.baseURL = "http://localhost:5000/api";
axios.defaults.baseURL = "https://soportebo.com/api";
// axios.defaults.baseURL = "https://ap.aguapotable.org/api";

axios.interceptors.request.use(
  (config) => {
    const token_seguridad = window.localStorage.getItem("token_seguridad");

    if (token_seguridad) {
      config.headers.Authorization = "Bearer " + token_seguridad;
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestGenerico = {
  getOnly: (url) => axios.get(url),
  get: (url, parameter) =>
    axios.get(url, {
      params: { id: parameter },
    }),
  getByJason: (url, parameters) => axios.get(url, { params: parameters }),
  post: (url, body) => axios.post(url, body),
  put: (url, body) => axios.put(url, body),
  delete: (url) => axios.delete(url),
  getPdf: (url, parameter) =>
    axios.get(url, {
      params: { id: parameter },
      responseType: "blob", // here I am forcing to receive data in a Blob Format
    }),

    getPdfByDate: (url, fromDate, toDate) =>
    axios.get(url, {
      params: { from: fromDate,  to: toDate},
      responseType: "blob", // here I am forcing to receive data in a Blob Format
    }),

    getPdfOnly: (url) =>
    axios.get(url, {
      responseType: "blob", // here I am forcing to receive data in a Blob Format
    }),

    getByJasonPdf: (url, parameters) => 
    axios.get(url, { 
      params: parameters,
      responseType: "blob",
    }),
};

export default requestGenerico;
