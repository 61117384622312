import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import {Grid, ListItem, ListItemText} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



const useStyles = makeStyles((theme) => ({

  menuu:{
    
      display: 'flex',
      flexDirection: 'column',
      color: "#fff",
      alignItems: 'center',
      justifyContent: "center",
      '& > *': {
        margin: theme.spacing(1),
      },
      position:"relative",
    
  },

  negrita:{
    fontWeight: "bold",
  },
 
  
  
  hero: {
    
    backgroundImage: `linear-gradient(rgba(0, 168, 255, 0.2),rgba(0, 168, 255, 0.0),rgba(0, 168, 255, 0.0),rgba(0, 168, 255, 0.0), rgba(63, 167, 121, 0.6)), url('https://soportebo.net/header-background.jpg')`,
    minHeight: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    width:"100%",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em"
    },
  },

  blogsContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    

  },
  blogTitle: {
    fontWeight: 800,
    width:"100%",
    
    paddingBottom: theme.spacing(1)
  },
  card: {
    maxWidth: "100%",

  },
  media: {
    height: 550,
  
  },
  cardActions: {
    display: "flex",
    margin: "0 10px",
    justifyContent: "space-between"
  },
  author: {
    display: "flex"
  },
  
  logI: {
    padding:5
  
  },

  
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const Notice = () => {
  const classes = useStyles();
  
  const [exn1, setExn1] = React.useState(false);
  const handleExn1Click = () => {
    setExn1(!exn1);
  };

  const [exn2, setExn2] = React.useState(false);
  const handleExn2Click = () => {
    setExn2(!exn2);
  };

  const [exn3, setExn3] = React.useState(false);
  const handleExn3Click = () => {
    setExn3(!exn3);
  };

  const [exn4, setExn4] = React.useState(false);
  const handleExn4Click = () => {
    setExn4(!exn4);
  };

  const [exn5, setExn5] = React.useState(false);
  const handleExn5Click = () => {
    setExn5(!exn5);
  };
  
  // estatutos
  const [estatutos, setEstatutos] = React.useState(false);
  const [scrollEstatutos, setScrollEstatutos] = React.useState('paper');

  const handleClickEstatutos = (scrollType) => () => {
    setEstatutos(true);
    setScrollEstatutos(scrollType);
  };

  const handleCloseEstatutos = () => {
    setEstatutos(false);
  };
  // Nosotros
  const [nosotros, setNosotros] = React.useState(false);
  const [scrollNosotros, setScrollNosotros] = React.useState('paper');

  const handleClickNosotros = (scrollType) => () => {
    setNosotros(true);
    setScrollNosotros(scrollType);
  };

  const handleCloseNosotros = () => {
    setNosotros(false);
  };
// Plan de trabajo
  const [planTrabajo, setPlanTrabajo] = React.useState(false);
  const [scrollPlanTrabajo, setScrollPlanTrabajo] = React.useState('paper');

  const handleClickPlanTrabajo = (scrollType) => () => {
    setPlanTrabajo(true);
    setScrollPlanTrabajo(scrollType);
  };

  const handleClosePlanTrabajo = () => {
    setPlanTrabajo(false);
  };
// deudores
const [deudores, setDeudores] = React.useState(false);
const [scrollDeudores, setScrollDeudores] = React.useState('paper');

const handleClickDeudores = (scrollType) => () => {
  setDeudores(true);
  setScrollDeudores(scrollType);
};

const handleCloseDeudores = () => {
  setDeudores(false);
};


  return (
    
    <div className={classes.Not}>
      
      {/* Dialogs */}
      <Box >
        <Grid container  className={classes.hero}>
          <Grid item xs={12} className={classes.menuu}>
          MALLCO CHAPI
          <ButtonGroup variant="contained" aria-label="text primary button group" color="primary" size="small">
{/* ESTATUTOS */}
            <Dialog
              open={estatutos}
              onClose={handleCloseEstatutos}
              scroll={scrollEstatutos}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">ESTATUTOS</DialogTitle>
              <DialogContent dividers={scrollEstatutos === 'paper'}>
                <DialogContentText
                  id="scroll-dialog-description"
                  tabIndex={-1}
                  align="justify"
                >
                ESTATUTO ORGANICO
                </DialogContentText>
                <Typography align="center" fontWeight="bold">
                ASOCIACIÓN DE AGUA POTABLE Y SANEAMIENTO BÁSICO
                </Typography>
                <DialogContentText align="center" >
                “MALLCO CHAPI” con sigla AAP-MC 
                </DialogContentText >
                <DialogContentText align="justify">
                En fecha 16 de Febrero de 2019, en la comunidad de Mallco Chapi del Municipio de Sipe Sipe de la Provincia Quillacollo del departamento  de Cochabamba,  se han reunido los socios de la Entidad Prestadora de Servicio de Agua y Saneamiento (EPSA) denominada en adelante Asociación de Agua Potable y Saneamiento Básico “Mallco Chapi” con sigla AAP-MC, con el objetivo de analizar y aprobar sus estatutos orgánicos y reglamentos internos como entidad prestadora de servicios anteriormente mencionada.
                </DialogContentText>
                <DialogContentText>
                INTRODUCCIÓN 
                </DialogContentText>
                <DialogContentText >
                  El  Estado  a  través  del  Viceministerio  de  Agua  Potable  y  Saneamiento  Básico  es
                  responsable de normar políticas y promover asistencia técnica al sector de agua y
                  saneamiento  básico. La Autoridad de Fiscalización  y Control Social de Agua Potable y
                  Saneamiento Básico (AAPS) es la responsable de regular los servicios de agua y
                  saneamiento   a  nivel  nacional.  El  Gobierno  Autónomo  Municipal   de  Sipe  Sipe, es
                  responsable de la dotación y funcionamiento de servicios de agua y saneamiento de las
                  comunidades de su jurisdicción. Asimismo la Constitución Política del Estado, establece en
                  su Artículo 20 que: "Toda persona tiene derecho al acceso universal y equitativo a los
                  servicios básicos de agua potable, alcantarillado (…). 
                </DialogContentText>
                <DialogContentText align="justify">
                  La forma de Constitución o Modelo de Gestión elegido es: Asociación de Agua Potable; de
                  ahora en adelante denominado Asociación de Agua Potable y Saneamiento Básico “Mallco
                  Chapi” con sigla AAP-MC, para ello se ha considerado el número de habitantes y la decisión
                  de las autoridades presentes; así también que éste modelo de gestión de servicios es de
                  aplicación local (en una sola población) y de esencia comunitaria. Su vigencia legal está
                  respaldada por el Código Civil (Artículo 73-Comités sin personería), y reconocida por la Ley
                  No 2066 de Servicios de Agua Potable y Alcantarillado Sanitario (Artículo 8, inciso k). 
                </DialogContentText>
                <DialogContentText>
                ANTECEDENTES 
                </DialogContentText>
                <DialogContentText align="justify">
                  Teniendo claro los beneficios que representa para la salud de nuestra población, el contar
                  con un sistema de agua potable y/o saneamiento, y de la responsabilidad que debemos tener en relación   al   buen   uso,   buena   administración,   buena   operación   y  buen
                  mantenimiento de este servicio, se cuenta con el presente documento de Estatuto Orgánico
                  y Reglamento Interno de la EPSA, el mismo que regirá los derechos y obligaciones de la
                  Directiva que administrará el sistema de agua potable y beneficiarios del mismo, para que
                  el sistema tenga una duración prolongada. 
                </DialogContentText>
                <DialogContentText align="center"> 
                CAPITULO I
                </DialogContentText>
                <DialogContentText align="center">
                DISPOSICIONES LEGALES 
                </DialogContentText>
                <DialogContentText align="justify">              
                  <span className={classes.negrita}>Artículo  1.-</span> La Entidad Prestadora  de Servicios  de Agua y Saneamiento  denominada
                  Asociación de Agua Potable y Saneamiento Básico “Mallco Chapi” con sigla AAP-
                  MC, se conforma con los siguientes objetivos: 
                </DialogContentText>
                <DialogContentText>
                a.     Administrar,  operar  y mantener  el sistema  de agua  potable  y/o saneamiento  en
condiciones  técnicas  y económicas  satisfactorias,  dando cumplimiento  a normas,
reglamentos e instrucciones que rigen en el sector del agua. 

                </DialogContentText>
                <DialogContentText>
                b.     Lograr la participación efectiva y comprometida de los usuarios en todas las etapas
que  implica:  La  planificación,  construcción,  administración,  operación  y
mantenimiento del sistema de agua potable y saneamiento. 

                </DialogContentText>
                <DialogContentText>
                c.      Efectuar trabajos de promoción, educación sanitaria entre los habitantes de la zona
atendida para lograr el uso efectivo y sostenido del sistema de agua y saneamiento. 

                </DialogContentText>
                <DialogContentText>
                d.     La Asociación de Agua Potable y Saneamiento Básico “Mallco Chapi” con sigla
AAP-MC es una organización de usuarios del agua potable, autónoma, con patrimonio propio,
destinada a prestar servicios básicos de agua potable, no persigue fines de lucro y tiene los
objetivos siguientes: 
                </DialogContentText>
                <DialogContentText>
                Objetivos Específicos: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.  Mantener la cobertura total en el área de prestación de servicios, impulsando la
ampliación del sistema de agua potable a partir del requerimiento de los usuarios
según corresponda. 
                    </ListItem>
                    <ListItem>
                    b.  Generar recursos suficientes a partir de la prestación de servicios, con la finalidad
de buscar auto sostenibilidad. 
                    </ListItem>
                    <ListItem>
                    c.  Manejar de manera transparente la administración del servicio.
                    </ListItem>
                    <ListItem>
                    d.  Manejar de manera eficiente y autonomía los recursos económicos y activos de la Asociación de Agua Potable y Saneamiento Básico “Mallco Chapi” con sigla AAP-MC.
                    </ListItem>
                    <ListItem>
                    e.  Asegurar el servicio permanente del agua para consumo humano, en condiciones
de calidad. 
                    </ListItem>
                    <ListItem>
                    f.   Velar por la conservación, mantenimiento y operación del sistema agua potable de
la Comunidad Mallco Chapi. 
                    </ListItem>
                    <ListItem>
                    g.  Fomentar  el uso adecuado  y racional de agua potable. Coadyuvando  a si a la
protección  y manejo  sostenible  de los  recursos  naturales  como  suelo,  agua  y
vegetación 
                    </ListItem>
                    <ListItem>
                    h.  Establecer nexos y acuerdos de cooperación con organismos e instituciones a fines
para el logro de objetivos comunes o conectados. 
                    </ListItem>
                    <ListItem>
                    i.  Efectuar actividades, de promoción, educación ambiental y sanitaria entre los
habitantes de la comunidad para lograr el uso racional y sostenido de todo el sistema
de agua. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>Artículo 2.-</span> El Recurso Agua.- El recurso agua del sistema de agua potable está destinado estrictamente a los siguientes usos. 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    •        Consumo humano, es decir para beber, preparar alimentos, higiene personal 
                    </ListItem>
                    <ListItem>
                    •        Uso en el baño. 
                    </ListItem>
                    <ListItem>
                    •  Consumo domiciliario, lavado de prendas de vestir, lavado de utensilios, aseo de la casa. 
                    </ListItem>
                    <ListItem>
                    •        Regado de plantas ornamentales en maseta y frutales dentro la vivienda. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}> CAPITULO II</span>
                </DialogContentText>
                <DialogContentText>
                DE LA ORGANIZACIÓN 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>Artículo 3.-</span>  La organización entra en funcionamiento, una vez que haya sido conformada y posesionados en sus cargos los miembros de la Directiva de la  Asociación. 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    La Asociación  es responsable  de los servicios  básicos  y estará constituida  por un
directorio de siete (7) miembros. El directorio será elegido democráticamente  en una
asamblea de usuarios convocada para el efecto. 
                    </ListItem>
                    <ListItem>
                    •    El  directorio  podrá  elegir  o  convocar  de  manera  opcional  a  los  cargos  de  un(a) administrador y un(a) operador (a) para garantizar el buen funcionamiento del sistema; 
debiendo ser estos funcionarios de la Asociación remunerados. 
                    </ListItem>
                    <ListItem>
                    •  Los administradores y/u operadores deberán participar activamente en las acciones y
decisiones de la Asociación. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}> CAPITULO III</span>
                </DialogContentText>
                <DialogContentText>
                DE LA ADMISIÓN, DERECHOS Y OBLIGACIONES DE LOS USUARIOS 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>Artículo 4.-</span> El Usuario.- Persona natural o jurídico que cuente con la autorización de la asamblea para su filiación. 
                </DialogContentText>
                <DialogContentText>
                  <ListItem>
                  •  El usuario nuevo deberá cumplir con los reglamentos  de filiación tanto económicos
como operativos que la Asociación establezca. 
                  </ListItem>
                </DialogContentText>
                <DialogContentText>
                Son usuarios fundadores  de la Asociación  de Agua Potable  y Saneamiento  Básico
“Mallco Chapi” con sigla AAP-MC, todas las personas, que desde el inicio trabajaron y
aportaron para la construcción del sistema, los mismos cuentan con su acción. 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>Artículo 5.-</span> Nuevos Usuarios.- Las personas que quieran ingresar a la Asociación como NUEVOS USUARIOS DEL AGUA, deben cumplir los siguientes requisitos: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    • Solicitar por escrito al Directorio de la Asociación su ingreso como usuario.
                    </ListItem>
                    <ListItem>
                    • Fotocopia de transferencia de compra y venta o derecho propietario, adjuntando el
plano a su nombre.

                    </ListItem>
                    <ListItem>
                    • Fotocopia  de C.I. 
                    </ListItem>
                    <ListItem>
                    • Manifestación escrita de la Junta Vecinal, donde indique que la propiedad no tenga
impedimentos para la conexión del servicio de agua.

                    </ListItem>
                    <ListItem>
                    • Croquis de la vivienda. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                a.  Debe vivir en la Comunidad o  la zona hasta donde es posible llegar con el servicio.

                </DialogContentText>
                <DialogContentText>
                b.  Acreditar su identidad y lugar de residencia con fotocopia de cédula de identidad.

                </DialogContentText>
                <DialogContentText>
                c.  Someterse al Estatuto Orgánico y Reglamento Interno de la Asociación. 
                </DialogContentText>
                <DialogContentText>
                d.  El nuevo usuario deberá cancelar por única vez su inscripción la suma de mil dólares
o su equivalente en pesos bolivianos a la Asociación como usuario, que será por todos
los trabajos y/o aportes realizados durante la construcción y/o ampliación del sistema de agua; en el caso que sea un usuario oriundo de la comunidad, este cancelará la
suma de trecientos cinco dólares o su equivalente en pesos bolivianos a la Asociación. 
                </DialogContentText>
                <DialogContentText>
                e.  El socio ingresante pagará el costo de instalación desde la matriz hasta su domicilio
incluyendo el medidor de consumo, o en su caso lo realizará la instalación con una
supervisión técnicamente adecuada. 
                </DialogContentText>
                <DialogContentText>
                f.   Demostrar con fotocopias simples de documentos que acredite ser dueño del terreno
o la casa en la comunidad. 
                </DialogContentText>
                <DialogContentText>
                g.  No haber tenido ningún antecedente contrario a los intereses de la Asociación. 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>Artículo 6.-</span> De las Obligaciones del Usuario: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    •    Permitir la instalación del micromedidor u otros trabajos de conexión en su domicilio. 
                    </ListItem>
                    <ListItem>
                    •  Proteger el micromedidor de daños físicos o robo, debiendo reponer en caso de perdida
por cualquier motivo. 
                    </ListItem>
                    <ListItem>
                    •  Todo  usuario  está  obligado  a  asistir  a  las  reuniones  ordinarias  y  extraordinarias
convocadas por la Asociación. 
                    </ListItem>
                    <ListItem>
                    •  Todo usuario debe cumplir con las obligaciones establecidas en el presente documento
y en Asamblea General. 
                    </ListItem>
                    <ListItem>
                    •  Comunicar al operador (plomero) cualquier desperfecto que se le presente en forma
inmediata, para su respectiva reparación 
                    </ListItem>
                    <ListItem>
                    •  Todo usuario está obligado a mantener las instalaciones en su propiedad, en buenas
condiciones  físicas y de funcionamiento  y cualquier desperfecto que se le presente
deberse comunicado al personal de la Asociación. 
                    </ListItem>
                    <ListItem>
                    •  Todo usuario debe cancelar la tarifa de agua establecida en forma mensual, dentro el
plazo establecido, o según acuerdo firmado en asamblea de usuarios. 
                    </ListItem>
                    <ListItem>
                    •  Todo usuario que no pague la tarifa regularmente, caerá en mora y será factible a ser
sancionado de acuerdo al reglamento. 
                    </ListItem>
                    <ListItem>
                    •  Está prohibido comercializar el agua de la conexión domiciliaria y utilizar el agua de
cualquier  parte del sistema  o para fines que estén expresamente  prohibidos  en el
reglamento. 
                    </ListItem>
                    <ListItem>
                    •  Los usuarios nuevos, deberán pagar el derecho de conexión, uso del servicio de agua
de acuerdo a su consumo, y en conformidad a la estructura tarifaria aprobada. 
                    </ListItem>
                    <ListItem>
                    •  Toda conexión que no cuente con autorización de la Asociación será considerada como
clandestina y será sujeta a las sanciones establecidas en el reglamento
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>Artículo 7.-</span> De los Derechos del Usuario: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    •    Emitir su voz y voto en la Asamblea General 
                    </ListItem>
                    <ListItem>
                    •  Exigir  el  servicio  del  sistema  de  agua  potable  conforme  a  los  principios  de  igualdad  y
proporcionalidad, cuando haya que racionalizar el servicio, debe ser para todos los Asociados por
igual. 
                    </ListItem>
                    <ListItem>
                    •    Todos los usuarios, sea hombre o mujer (mayor de edad) pueden elegir y ser elegidos en el 
 
 Directorio y/o comisiones especiales de la Asociación. 
  
                    </ListItem>
                    <ListItem>
                    •    A reclamar al Directorio por algún desperfecto o irregularidades en el servicio. 
                    </ListItem>
                    <ListItem>
                    •    Participar con derecho a voz y voto en todas las instancias orgánicas de la Asociación. 
                    </ListItem>
                    <ListItem>
                    •  Todo usuario mayor de edad puede ser habilitado y postulado para ser elegido miembro
de la Directiva de la Asociación. 
                    </ListItem>
                    <ListItem>
                    •    El ejercicio de los derechos está condicionado al cumplimiento de las obligaciones 
 
                    </ListItem>
                    <ListItem>
                    •  Todo socio podrá dar a conocer y reclamar sobre el servicio de agua, cuando este tenga
fallas y exigir solución de los problemas por parte de la Asociación. 
                    </ListItem>
                    <ListItem>
                    •  A solicitar licencia a las reuniones de socios o el directorio, misma que debe ser justificada y por
escrito. 
                    </ListItem>
                    <ListItem>
                    •  A suplencia en caso de impedimento de estar presente en las reuniones de los Asociados,
mediante carta dirigida al Directorio, en la cual haga conocer los motivos y quién es la persona
suplente, en todo caso, la misma debe ser una persona mayor de edad y con plenas capacidades
para auto obligarse. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>CAPITULO IV </span>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>DE LAS DISPOSICIONES DE LA ASOCIACIÓN </span>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>Artículo  8.- </span> El  directorio  de  la  Asociación  está  conformado  como  mínimo  por  siete miembros: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    -      Presidente 
                    </ListItem>
                    <ListItem>
                    -      Vicepresidente 
                    </ListItem>
                    <ListItem>
                    -      Secretario de actas 
                    </ListItem>
                    <ListItem>
                    -      Secretario de hacienda 
                    </ListItem>
                    <ListItem>
                    -      Secretario de deportes 
                    </ListItem>
                    <ListItem>
                    -      Vocal 1 
                    </ListItem>
                    <ListItem>
                    -      Vocal 2
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                   
Artículo 9.- La forma de elección del Directorio de la Asociación será por código del 1 al 
 
 50, posteriormente del 51 al 100 y así sucesivamente en cada futura elección; aclarando
 que dentro  la directiva no puede estar conformado por parientes o familiares. 
  
                </DialogContentText>
                <DialogContentText>
                Artículo 10.- Los miembros del directorio de la Asociación, permanecerán en sus funciones
durante (2) dos años pudiendo ser ratificados a consideración  de la asamblea general.
Pasado este tiempo, la renovación del directorio quedará bajo la decisión de la Asamblea
General, conforme a una evaluación de su desempeño. 
                </DialogContentText>
                <DialogContentText>
                Artículo 11.- Para ser miembro del directorio de la Asociación se requiere: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    -  Gozar de derechos y obligaciones de ciudadanía. 
                    </ListItem>
                    <ListItem>
                    -  Residir en la comunidad en forma permanente y ser usuario del sistema. 
                    </ListItem>
                    <ListItem>
                    -  Con preferencia, saber leer y escribir (no indispensable). 
                    </ListItem>
                    <ListItem>
                    -  Estar al día en los pagos correspondientes al servicio de agua. 
                    </ListItem>
                    <ListItem>
                    -  No  haber incurrido en hechos y actos contrarios  al interés de Asociación de Agua Potable y Saneamiento Básico “Mallco Chapi” con sigla AAP-MC. 
  
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Artículo 12.- Impedimentos para ser Directivos: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.  Los que tienen obligaciones pendientes con la Asociación. 
                    </ListItem>
                    <ListItem>
                    b.  En una misma Directiva los que tengan entre si parentesco hasta el segundo grado
de consanguinidad. 
                    </ListItem>
                    <ListItem>
                    c.  Los funcionarios públicos con grado jerárquico con jurisdicción y competencia en la
comunidad a la que pertenece la Asociación. 
                    </ListItem>
                    <ListItem>
                    d.  Los  observados  por  delitos  cometidos  en  la  organización,  funcionamiento  y
administración de la Asociación. 
                    </ListItem>
                    <ListItem>
                    e.  Los que no están en condición de usuarios de Agua Potable. 
                    </ListItem>
                    <ListItem>
                    f.   Los que cometan actos que atenten directa o indirectamente contra los objetivos y
fines de la Asociación. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                  Artículo 13.- Una vez realizada la elección de los miembros de la directiva, se procederá a
                  la  posesión  y posteriormente  se  elaborará  el  acta  correspondiente  con  la  rúbrica  de
                  constancia de los usuarios presentes. 
                </DialogContentText>
                <DialogContentText>
                Artículo 14.- Sobre el funcionamiento del sistema.
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    -  La operación y mantenimiento del sistema de agua potable estará a cargo de la directiva
de la Asociación y de los funcionarios contratados por la misma (plomeros). 
                    </ListItem>
                    <ListItem>
                    -  Por otra parte, la organización y todos los usuarios del sistema son responsables de la
vigilancia, cuidado y buen uso de las instalaciones. 
                    </ListItem>
                    <ListItem>
                    -  Todos los usuarios  están obligados  a cancelar la tarifa establecida  de acuerdo  a lo
estipulado en el reglamento de tarifas y sanciones. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                DE LAS FUNCIONES DE LA DIRECTIVA 
                </DialogContentText>
                <DialogContentText>
                Artículo 15.- El Directorio de la Asociación tendrá las siguientes funciones: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.   Hacer respetar y cumplir las decisiones de la Asamblea General de Usuarios 
                    </ListItem>
                    <ListItem>
                    b.   Convocar a Asambleas Generales de Usuarios para tratar asuntos relacionados con el
funcionamiento, operación y mantenimiento del sistema de agua potable. 
                    </ListItem>
                    <ListItem>
                    c.   Realizar el cálculo real de tarifas y poner en consideración para ser aprobado por la
Asamblea de usuarios. El mismo podrá ser modificado en Asamblea extraordinaria con
dos tercios de votos. 
                    </ListItem>
                    <ListItem>
                    d.   Autorizar  o suspender  los  servicios  domiciliarios,  conforme  a lo establecido  en el
presente Estatuto y Reglamento (debidamente justificados). 
                    </ListItem>
                    <ListItem>
                    e.   Revisar el estado de resultados mensual (ingresos y egresos) así como el balance
anual 
                    </ListItem>
                    <ListItem>
                    f.    El  Secretario  de  Hacienda,  deberá  presentar  un  Informe  Anual  Financiero  de  la 
 
 Asociación ante la Asamblea General. 
  
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Artículo 16.- Son funciones del Presidente: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.  Representar legalmente a la Asociación de Agua Potable y Saneamiento Básico
“Mallco Chapi” con sigla AAP-MC y suscribir a nombre de él, todo tipo de actas,
convenios y contratos. 
                    </ListItem>
                    <ListItem>
                    b.  Convocar a Asamblea General y dirigir las reuniones. 
                    </ListItem>
                    <ListItem>
                    c.  Representar a la Asociación ante el Gobierno Municipal u otras instancias legalmente
establecidas. 
                    </ListItem>
                    <ListItem>
                    d.  Solicitar apoyo y asistencia técnica al Gobierno Municipal u otras instituciones según
requiriera. 
                    </ListItem>
                    <ListItem>
                    e.  Firmar la correspondencia oficial de la Asociación.
                    </ListItem>
                    <ListItem>
                    f.   Dirigir y controlar la administración del servicio de agua potable en forma conjunta con
el resto del directorio. 
                    </ListItem>
                    <ListItem>
                    g.  Apoyar  al Secretario  de hacienda  en el manejo  y custodia  de los fondos  y en la
administración del servicio de agua. 
                    </ListItem>
                    <ListItem>
                    h.  Cumplir y hacer cumplir el Estatuto Orgánico, Reglamento Interno y decisiones de la
asamblea. 
                    </ListItem>
                    <ListItem>
                    i.   Convocar a eventos y concentraciones para representar los intereses de la Asociación
de agua, ante autoridades nacionales e instituciones. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Artículo 17.- Son funciones del Vice Presidente: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.   Es el directo colaborador del presidente. 
                    </ListItem>
                    <ListItem>
                    b.   En   ausencia   de   presidente   representara   en   diferentes   eventos   o   funciones
establecidas. 
                    </ListItem>
                    <ListItem>
                    c.  Cumplir y hacer cumplir el estatuto, reglamento y decisiones de la asamblea. 
 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Artículo 18.- Son funciones del Secretario de Actas: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
 
                    a.   Elaborar las Actas de las Reuniones de la Asociación y registrarlas en el Libro de Actas. 
                    </ListItem>
                    <ListItem>
                    b.   Mantener al día la documentación a su cargo, archivo de toda la documentación de la 
 
 Asociación. 
                      
                    </ListItem>
                    <ListItem>
                    c.   Ser  responsable  del  cuidado  de  los  libros  de  su  competencia  tales  como  actas,
estatutos y reglamentos, contratos, etc. 
                    </ListItem>
                    <ListItem>
                    d.   Reemplazar  al  Vicepresidente,  asumiendo  sus  funciones  en  caso  de  ausencia  o
renuncia mientras se elige su reemplazante. 
                    </ListItem>
                    <ListItem>
                    e.   Convocar conjuntamente con el Presidente a asambleas y reuniones del directorio. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Artículo 19.- Son obligaciones del Secretario de Hacienda: 


                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.     Controlar  los fondos  y activos  de la Asociación  que se produzcan  por cualquier
concepto 
                    </ListItem>
                    <ListItem>
                    b.     Efectuar los pagos de las obligaciones contraídas, con la aprobación del Presidente. 
 
                    </ListItem>
                    <ListItem>
                    c.      Tener  al día el movimiento  contable  de ingresos  y egresos  de los fondos  de la 
 
 Asociación. 
                    </ListItem>
                    <ListItem>
                    d.     Llevar la lista de usuarios, con los cobros por la prestación del servicio y otros  cobros que genere la Asociación. 
                    </ListItem>
                    <ListItem>
                    e.     Llevar un registro de la recepción de materiales y herramientas con los que cuenta la 
 
 Asociación. 
                    </ListItem>
                    <ListItem>
                    f.      Entregar materiales y herramientas solicitados por los funcionarios, tomando nota en
un registro.
                    </ListItem>
                    <ListItem>
                    g.     Controlar estrictamente la entrada y salida de materiales y herramientas 
                    </ListItem>
                    <ListItem>
                    h.     Responsabilizarse  por  todos  los  materiales  y  herramientas  que  estén  bajo  su
custodia. 
                    </ListItem>
                    <ListItem>
                    i.       Comunicar a la Asociación la necesidad de adquirir materiales que se necesite para
la prestación del servicio. La adquisición de bienes o servicios serán autorizados por
el presidente previa presentación de cuadros comparativos con sus comprobantes
establecidos y aprobados por la asamblea. 
                    </ListItem>
                    <ListItem>
                    j.       Presentar cuadros comparativos para la adquisición de bienes o servicios de acuerdo
a montos y comprobantes establecidos y aprobados por la asamblea. 
 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Artículo 20.- Funciones del Secretario de Deportes y Vocales. 
                </DialogContentText>
                <DialogContentText>
                Son funciones del Secretario de Deportes: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
 
                    a.      Desarrollar planes, programas deportivos y recreativos, comprometidos con la justa
entrega de un deporte diversificado y una recreación accesible, promoviendo la unión
de las familias de la comunidad y su entorno. 
                    </ListItem>
                    <ListItem>
                    b.      Difundir  y  motivar  la  participación  activa  y  constante  de  los  Asociados  en  las
actividades deportivas. 
                    </ListItem>
                    <ListItem>
                    c.      Coordinar  y  realizar  actividades  de  deporte  con  la  Asociación  y/o  con  otras
instituciones, buscando el desarrollo social sin olvidar los principios de respeto,
responsabilidad, lealtad, honradez, trabajo en equipo y solidaridad. 
                    </ListItem>
                    <ListItem>
                    d.      Garantizar que los procesos deportivos y recreativos se lleven a cabo de conformidad
con los planes y programas establecidos. 
                    </ListItem>
                    <ListItem>
                    e.      El Fondo para el deporte se conformará con los siguientes recursos: 
 
                    </ListItem>
                    <ListItem>
                      <List>
                        <ListItem>
                        -      Con programas o eventos especiales que se realicen para obtener ingresos para el 
Fondo. 
                        </ListItem>
                        <ListItem>
                        -      Con los obtenidos en su propio desarrollo. 
                        </ListItem>
                        <ListItem>
                        -      Con el aporte aprobado por la Asamblea. 
 
                        </ListItem>
                        <ListItem>
                        -      Con las donaciones y auxilios que se realicen por personas naturales o jurídicas con destino al incremento del Fondo. 
                        </ListItem>
                      </List>
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Son funciones de los Vocales 1 y 2. 
                </DialogContentText>
                <DialogContentText>
                <ListItem>
                      <List>
                        <ListItem>
                        a.     Citar a reuniones ordinarias y extraordinarias de la Asociación 
                        </ListItem>
                        <ListItem>
                        b.     Apoyar en las campañas de comunicación de la Asociación 
                        </ListItem>
                        <ListItem>
                        c.      Socializar los estatutos y reglamentos de la Asociación con los usuarios 
                        </ListItem>
                        <ListItem>
                        d.     Comunicar a los usuarios en caso de cortes de servicio por mantenimiento, nuevos proyectos etc.  
                        </ListItem>
                      </List>
                    </ListItem>
                </DialogContentText>
                <DialogContentText>
                Artículo 21.- Son funciones de Operadores(as) (Plomeros): 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.     Ser responsable de las acciones de operación y mantenimiento del sistema de agua
y/o saneamiento. 
                    </ListItem>
                    <ListItem>
                    b.     Ejecutar las sanciones impuestas por la Asociación (autorizadas de forma escrita) 
 
 como cortes de servicio. 
  
                    </ListItem>
                    <ListItem>
                    c.      Realizar las reparaciones en el sistema de agua potable y/o saneamiento. 
                    </ListItem>
                    <ListItem>
                    d.     Vigilar  la  fuente  e  instalaciones  para  preservar  la  calidad  del  agua  y  buena
conservación del sistema. 
                    </ListItem>
                    <ListItem>
                    e.     Realizar  nuevas  conexiones  y  reconexiones  previa  autorización  escrita  de  la 
 
 Asociación, previo pago por parte del usuario o solicitante. 
                    </ListItem>
                    <ListItem>
                    f.      Elaborar un programa de actividades y dar a conocer a la Asociación las mismas,
realizar las actividades de limpieza, desinfección del tanque e inspecciones periódicas
que se realice. 
                    </ListItem>
                    <ListItem>
                    g.     Elaborar informes técnicos periódicos al directorio de la Asociación. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Todo trabajo que cumpla el operador (plomero) deberá ser reconocido, negociable con el
mismo, en base a un monto establecido por el Directorio y la asamblea de usuarios. 
                </DialogContentText>
                <DialogContentText>
                Artículo 22.- Los documentos que maneja la Directiva, son los siguientes: 

 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    •   Un Libro de Actas. 
                    </ListItem>
                    <ListItem>
                    •   Plano del sistema (bombeo, aducción, almacenamiento, red). 
                    </ListItem>
                    <ListItem>
                    •   Archivo de solicitudes y conexiones domiciliarias. 
                    </ListItem>
                    <ListItem>
                    •   Registro de usuarios.
                    </ListItem>
                    <ListItem>
                    •   Estados de cuentas de los usuarios. 
                    </ListItem>
                    <ListItem>
                    •   Libro de ingresos y egresos (libro de Caja o Contabilidad). 
                    </ListItem>
                    <ListItem>
                    •   Talonarios de recibos numerados o tarjetas de control. Para el cobro de tarifas. 
                    </ListItem>
                    <ListItem>
                    •   Planillas del movimiento de caja mensual. 
                    </ListItem>
                    <ListItem>
                    •   Cartas y/o solicitudes de asistencia técnica. 
                    </ListItem>
                    <ListItem>
                    •   Registro de trabajos de reparación/mantenimiento y lectura. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>DE LAS REUNIONES </span>
                </DialogContentText>
                <DialogContentText>
                Artículo  23.-  Para  llevar  adelante  sus  actividades,  la  Asociación  de  Agua  Potable  y 
 
 Saneamiento Básico “Mallco Chapi” con sigla AAP-MC establece, dos tipos de reuniones: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.   Las reuniones de Directorio cuando se requiere a convocatoria del presidente. 
                    </ListItem>
                    <ListItem>
                    b.   La Asamblea General de usuarios: La Asamblea de Usuarios es considerada como
máxima autoridad, y a ella compete tomar las máximas decisiones de planificación y
ejecución de proyectos. 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Tanto las reuniones de Directorio, como la Asamblea de Usuarios podrán ser ordinarias o
extraordinarias.  Y serán realizadas en el lugar de costumbre o donde sea citado por el
presidente. 
                </DialogContentText>
                <DialogContentText>
                La Asamblea General de Usuarios se reunirá ordinariamente cuatro veces al año siendo
esta trimestralmente (en cada primera quincena), fechas establecidas por la asamblea o en
caso  de emergencia  (de acuerdo  ha llamado del directorio),  siendo  sus funciones  las
siguientes: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    •  Elegir el Directorio de la Asociación de Agua Potable y Saneamiento Básico “Mallco
Chapi” con sigla AAP-MC y promover cambios o reemplazos siempre y cuando estos
sean justificados. 
                    </ListItem>
                    <ListItem>
                    •  Identificar y promover iniciativas y sugerencias de beneficio colectivo, en relación con el
sistema de agua potable. 
                    </ListItem>
                    <ListItem>
                    •  Vigilar  el  cumplimiento  de  funciones  que  competen  al  Directorio  y  al  personal
contratado.
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>ORDEN DEL DÍA  </span>
                </DialogContentText>
                <DialogContentText>
                   
Artículo 24.- Para llevar adelante cada reunión se elaborará un orden del día sobre los puntos 
a tratarse,  tanto  en las  reuniones  de directorio,  como  para  las  asambleas  de usuarios,
en forma mínima se deberá tratar los siguientes puntos:
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    •    Lectura y aprobación del Orden del Día 
                    </ListItem>
                    <ListItem>
                    •    Control de asistencia 
                    </ListItem>
                    <ListItem>
                    •    Lectura, aprobación y firma del acta anterior 
                    </ListItem>
                    <ListItem>
                    •    Informe de actividades económicas y operativas (según corresponda). 
                    </ListItem>
                    <ListItem>
                    •    Lectura de comunicaciones. 
                    </ListItem>
                    <ListItem>
                    •    Acuerdos, resoluciones y compromisos 
                    </ListItem>
                    <ListItem>
                    •    Varios 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Para el caso de reuniones o asambleas extraordinarias se tratará únicamente el asunto
para  el  que  fueron  convocados.  Cualquier  usuario  podrá  asistir  a  las  reuniones  de
Directorio, salvo el caso en que se acordara reunirse en forma reservada. 
                </DialogContentText>
                <DialogContentText>
                Artículo  25.-  La  no  asistencia  a  dos  reuniones  ordinarias  consecutivas  y  sin  causa
justificada de cualquiera de los miembros del Directorio, será motivo para que la Asamblea
General, considere su cambio y/o respectiva sanción. 
                </DialogContentText>
                <DialogContentText>
                Artículo 26.- La renuncia de cualquier miembro del Directorio deberá ser presentado por
escrito, y debidamente justificado ante la Asamblea General. 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>CAPITULO V  </span>
                </DialogContentText>
                <DialogContentText>
                DE LAS DETERMINACIÓN Y REAJUSTE DE TARIFAS 
                </DialogContentText>
                <DialogContentText>
                   
Artículo 27.- La tarifa por consumo de agua potable será determinada por la Asociación,
bajo un modelo de cálculo, en la que se toma en cuenta el criterio técnico y deberá cubrir
lo siguiente: 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    • Gastos de operación y mantenimiento del sistema. 
                    </ListItem>
                    <ListItem>
                    • Gastos administrativos (papelería, recibos, etc.) 
 
                    </ListItem>
                    <ListItem>
                    • Pago a funcionarios de la Asociación (administradores/operadores) 
 
                    </ListItem>
                    <ListItem>
                    • Fondo de ahorro, que servirá, para reparaciones, futuras ampliaciones del sistema y
renovación de componentes en el tiempo (reposición de activos)
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                Artículo 28.- Las tarifas serán reajustadas cuando así se vea por conveniente, verificando
previamente que la tarifa establecida no cubra con la sostenibilidad de la Asociación. 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>CAPITULO VI  </span>
                </DialogContentText>
                <DialogContentText>
                DE LAS PROHIBICIONES 
                </DialogContentText>
                <DialogContentText>
                Artículo 29.- De las prohibiciones. 
                </DialogContentText>
                <DialogContentText>
                  <List>
                    <ListItem>
                    a.  Está prohibido entrar en mora (deber) por el pago de tarifa por más de 2 meses. 
 
                    </ListItem>
                    <ListItem>
                    b.  Está prohibido comercializar con el agua de la conexión domiciliaria o de cualquier parte
del sistema. 
                    </ListItem>
                    <ListItem>
                    c.  Está prohibido conectar bombas de succión directamente a la red de agua potable. 
                    </ListItem>
                    <ListItem>
                    d.  Está prohibido dañar y alterar el medidor o realizar algún tipo de acción que evite el
correcto registro del consumo de agua. 
                    </ListItem>
                    <ListItem>
                    e.  Está prohibido la conexión clandestina a los servicios de agua. 
                    </ListItem>
                    <ListItem>
                    f.   Está prohibido realizar rehabilitaciones clandestinas cuando el servicio ha sido cortado,
sin previo pago de la sanción. 
                    </ListItem>
                    <ListItem>
                    g.  Está prohibido Impedir al operador o lecturador que realice la lectura del medidor. 
 
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>CAPITULO VII  </span>
                </DialogContentText>
                <DialogContentText>
                DE LAS SANCIONES 
                </DialogContentText>
                <DialogContentText>
                Artículo 30.- La Asociación está facultada para aplicar sanciones a los usuarios: 
                </DialogContentText>
                <DialogContentText>
                  <ListItem>
                  a.  Llamadas de atención por escrito. 
 
                  </ListItem>
                  <ListItem>
                  b.  Suspensión temporal del servicio de abastecimiento de agua en los siguientes casos y
de acuerdo al reglamento adjunto: 
                  </ListItem>
                  <ListItem>
                    <List>
                    <ListItem>
                    •   Uso indebido del agua. 
                    </ListItem>
                    <ListItem>
                    •   Incumplimiento en él pagó dé tarifas (más de tres meses), previa notificación al corte
del servicio (dos notificaciones mínimas). 
                    </ListItem>
                    <ListItem>
                    •   Conexiones clandestinas. 
                    </ListItem>
                    <ListItem>
                    •   Riego de parcelas o cultivos de producción agrícola. 
                    </ListItem>
                    </List>
                  </ListItem>
                  <ListItem>
                  c.  Suspensión definitiva del servicio de agua, en caso de:
                  </ListItem>
                  <ListItem>
                    <List>
                      <ListItem>
                      •    Daños intencionados a las instalaciones del sistema de agua potable. 
                      </ListItem>
                      <ListItem>
                      •    Sucesiva reincidencia en las demás infracciones. 
                      </ListItem>
                      <ListItem>
                      •  En caso de morosidad en el pago de más de un año, haciendo caso omiso a las
notificadas emitidas, se procederá automáticamente al corte de suministro de agua,
sin necesidad de apelación alguna. 
 
                      </ListItem>
                    </List>
                  </ListItem>
                </DialogContentText>
                <DialogContentText>
                Artículo 31.- Si un socio o socia faltara el respeto a los miembros del directorio o a otros
socios, por: Primera vez, recibirá un llamado de atención; por segunda vez pagara una
multa que estará regido en el Reglamento Interno. 
 
                </DialogContentText>
                <DialogContentText>
                   
Artículo 32.- Toda infracción comprobada se sentará en acta y se procederá a anotar la
sanción impuesta en el registro de usuarios.
                </DialogContentText>
                <DialogContentText>
                Artículo 33.- En los casos de suspensión temporal se procederá a rehabilitar
automáticamente el servicio, una vez pagados los montos adeudados y multas
correspondientes. 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>CAPITULO VIII  </span>
                </DialogContentText>
                <DialogContentText>
                REGIMEN ECONOMICO: DEL PATRIMONIO, DE LAS FUENTES DE RECURSOS
ECONOMICOS. 
 
                </DialogContentText>
                <DialogContentText>
                Artículo 34.- Constituye el patrimonio de la Asociación de Agua Potable y Saneamiento 
 
 Básico “Mallco Chapi” con sigla AAP-MC, los siguientes: 
 
 
                </DialogContentText>
                <DialogContentText>
                  <ListItem>
                  Los materiales,  herramientas  y equipos  que se encuentren  en depósitos  de la 
 
 Asociación, debidamente inventariados y a nombre de la Asociación. 
  
 Toda la inversión  en el Sistema  de agua potable,  desde la parte colectora,  de
 captación, almacenamiento y distribución
 Las donaciones que pudiera lograr a nombre de la Asociación. 
  
 Los muebles e inmuebles o bienes que existan o que adquieran a nombre de la 
  
 Asociación. 
                  </ListItem>
                </DialogContentText>
                <DialogContentText>
                Artículo 35.- Las fuentes de recursos provienen principalmente de: 
                </DialogContentText>
                <DialogContentText>
                  <ListItem>
                  Los pagos mensuales por el servicio de agua - tarifas

                  </ListItem>
                  <ListItem>
                  Los pagos por certificados de aportaciones  o nuevos ingresos de los Asociados
                  </ListItem>
                  <ListItem>
                     
Los pagos por el consumo extra del mínimo establecido en asamblea General. 
 
 Aportes extraordinarios que pudieran realizar los Asociados 
  
 Créditos  que pudieran  conseguir  la Asociación  para realizar  inversiones  en las
 mejoras del sistema. 
                  </ListItem>
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>CAPITULO IX  </span>
                </DialogContentText>
                <DialogContentText>
                DEL DESTINO DE LOS RECURSOS 
                </DialogContentText>
                <DialogContentText>
                Artículo 36.- Los recursos de la Asociación serán para utilizarlo exclusivamente para la
administración, mantenimiento, mejoramiento del sistema y reposición, con el propósito de
mejorar la calidad del servicio. La administración esta delegada al Directorio, el mismo debe
someterse a lo siguiente: 
                </DialogContentText>
                <DialogContentText>
                   
Los recursos de la Asociación deben ser depositados a su cuenta, en una Cooperativa
Abierta o cualquier entidad financiera legalmente establecida para darle mayor trasparencia
a la administración  financiera.  La cuenta debe ser a nombre  de la Asociación  y será
administrada con las firmas del Presidente y Secretario de Hacienda. 
 
                </DialogContentText>
                <DialogContentText>
                   
Para facilitar los gastos corrientes de la Asociación, el Secretario (a) de Hacienda debe
manejar una caja chica de dos mil pesos bolivianos mensual, en caso de necesidad o
requerimiento, previa aprobación de la Directiva. 
 
                </DialogContentText>
                <DialogContentText>
                Los montos para gastos a efectuarse  arriba de dos mil pesos bolivianos,  deberán ser
consensuados y aprobado por la mayoría de los Asociados. 
                </DialogContentText>
                <DialogContentText>
                <span className={classes.negrita}>CAPITULO X  </span>
                </DialogContentText>
                <DialogContentText>
                DISPOSICIONES GENERALES, FINALES Y TRANSITORIAS 
                </DialogContentText>
                <DialogContentText>
                Disposiciones generales 
                </DialogContentText>
                <DialogContentText>
                Artículo 37.- Cualquier problema que se presente en el funcionamiento de la Asociación,
emergente de la interpretación de normas estatutarias, deberá ser puesto en conocimiento
del Directorio para tratarlo en una Asamblea.
                </DialogContentText>
                <DialogContentText>
                Disposiciones finales 
                </DialogContentText>
                <DialogContentText>
                Artículo 38.- La reforma de Estatutos será realizada exclusivamente por una Asamblea
General Extraordinaria, siendo nula cualquier determinación contraria. Las propuestas de
reformas y modificaciones deberán presentarse por escrito ante la Directiva, para que esta
ponga a consideración de la Asamblea. 
                </DialogContentText>
                <DialogContentText>
                Disposiciones transitorias 
                </DialogContentText>
                <DialogContentText>
                   
Artículo 39.- El presente Estatuto orgánico, entra en vigencia al momento de su aprobación
por los Asociados en fecha 16 de Febrero de  2019.
                </DialogContentText>

              </DialogContent>
            </Dialog>
              <Button onClick={handleClickEstatutos('paper')}>ESTATUTOS</Button>

{/* NOSTROS */}
<Dialog
              open={nosotros}
              onClose={handleCloseNosotros}
              scroll={scrollNosotros}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">NOSOTROS</DialogTitle>
              <DialogContent dividers={scrollNosotros === 'paper'}>
                <DialogContentText
                  id="scroll-dialog-description"
                  tabIndex={-1}
                  align="justify"
                >
                  Somos la asociciacion bla bla bla que se dedica a bla bla bla... basados segun normas bla bla bla... brindando un servicio bla bla bla para el bien de los comunarios que den buen uso del agua porque el agua es vida bla bla bla
                </DialogContentText>
                <DialogContentText align="center">
                  MISION
                </DialogContentText>
                <DialogContentText align="justify">
                Contribuir con calidad y eficacia los servicios de agua potable y saneamiento básico, para Proporcionar al bienestar, calidad de vida y el cuidado del entorno ecológico de los usuarios y habitantes de la comunidad de Mallco Chapi, a través del desarrollo integral de nuestro personal. 
                </DialogContentText>
                <DialogContentText align="center">
                  VISION
                </DialogContentText>
                <DialogContentText align="justify">
                  Ser una asociación sostenible y líder en suministro de agua potable y el saneamiento a las próximas generaciones de la comunidad de Mallco Chapi, satisfacer las necesidades de nuestros usuarios y que los indicadores de gestión nos coloquen como Organismo Operador Líder.
                </DialogContentText>
                <DialogContentText align="center">
                  NUESTROS VALORES
                </DialogContentText>
                <DialogContentText align="justify">
                  <List>
                    <ListItemText>
                      Comprimiso
                    </ListItemText>
                    <ListItemText>
                      Integridad
                    </ListItemText>
                    <ListItemText>
                      Respeto
                    </ListItemText>
                    <ListItemText>
                      Espiritu de Servicio
                    </ListItemText>
                    <ListItemText>
                      Honestidad
                    </ListItemText>
                    <ListItemText>
                      Calidad
                    </ListItemText>
                    <ListItemText>
                      Responsabilidad Social
                    </ListItemText>
                  </List>
                </DialogContentText>
                <DialogContentText align="justify">
                  OBJETIVOS
                </DialogContentText>
                <DialogContentText align="justify">
                  <List>
                    <ListItem>
                    •	Crear, construir, operar, mantener y administrar los Servicios de Agua Potable y saneamiento básico.
                    </ListItem>
                    <ListItem>
                    •	Asegurar el equilibrio económico y la sostenibilidad de la asociación.
                    </ListItem>
                  </List>
                </DialogContentText>
                <DialogContentText align="center">
                  DIRECTORIO 2020
                </DialogContentText>
                <DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      PRESIDENTE
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      FREDDY HUANCA PASCUAL
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      VICEPRESIDENTE
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      ADRIAN BUSTAMANTE VILLARROEL
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      SECRETARIO DE ACTAS
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    ADRIAN BUSTAMANTE VILLARROEL
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      SECRETARIA DE ACTAS
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      JULIETA BALDERRAMA PEÑALOZA
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      SECRETARIO DE DEPORTES
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      CARMELO VARGAS  †
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      VOCAL N° 1
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      CELIA BENITA VARGAS ESCOBAR
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      VOCAL N° 2
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      SEGUNDINO VARGAS
                    </Grid>
                  </Grid>
                  
                </DialogContentText>
                <DialogContentText aling="center">
                FUNCIONES DE LA DIRECTIVA DE LA ASOCIACIÓN DE AGUA POTABLE Y SANEAMIENTO BÁSICO DE MALLCO CHAPI
                </DialogContentText>
                <DialogContentText aling="justify">
                 1.	SON FUNCIONES DEL PRESIDENTE:
                 <List>
                     <ListItem>
                     a.	Representar legalmente a la Asociación de Agua Potable y Saneamiento Básico “Mallco Chapi” con sigla AAP-MC y suscribir a nombre de él, todo tipo de actas, convenios y contratos.
                     </ListItem>
                     <ListItem>
                     b.	Convocar a Asamblea General y dirigir las reuniones.
                     </ListItem>
                     <ListItem>
                     c.	Representar a la Asociación ante el Gobierno Municipal u otras instancias legalmente establecidas.
                     </ListItem>
                     <ListItem>
                     d.	Solicitar apoyo y asistencia técnica al Gobierno Municipal u otras instituciones según requiriera.
                     </ListItem>
                     <ListItem>
                     e.	Firmar la correspondencia oficial de la Asociación. 
                     </ListItem>
                     <ListItem>
                     f.	Dirigir y controlar la administración del servicio de agua potable en forma conjunta con el resto del directorio.
                     </ListItem>
                     <ListItem>
                     g.	Apoyar al secretario de hacienda en el manejo y custodia de los fondos y en la administración del servicio de agua.
                     </ListItem>
                     <ListItem>
                     h.	Cumplir y hacer cumplir el Estatuto Orgánico, Reglamento Interno y decisiones de la asamblea.
                     </ListItem>
                     <ListItem>
                     i.	Convocar a eventos y concentraciones para representar los intereses de la Asociación de agua, ante autoridades nacionales e instituciones.
                     </ListItem>
                 </List>
                </DialogContentText>
                <DialogContentText aling="justify">
                 2.	SON FUNCIONES DEL VICEPRESIDENTE:
                 <List>
                     <ListItem>
                     a.	Es el directo colaborador del presidente.
                     </ListItem>
                     <ListItem>
                     b.	En   ausencia   de   presidente   representara   en   diferentes   eventos   o   funciones establecidas.
                     </ListItem>
                     <ListItem>
                     c.	Cumplir y hacer cumplir el estatuto, reglamento y decisiones de la asamblea.
                     </ListItem>
                 </List>
                </DialogContentText>
                <DialogContentText aling="justify">
                3.	SON FUNCIONES DEL SECRETARIO DE ACTAS:
                 <List>
                     <ListItem>
                     a.	Elaborar las Actas de las Reuniones de la Asociación y registrarlas en el Libro de Actas.
                     </ListItem>
                     <ListItem>
                     b.	Mantener al día la documentación a su cargo, archivo de toda la documentación de la Asociación.
                     </ListItem>
                     <ListItem>
                     c.	Ser responsable del cuidado de los libros de su competencia tales como actas, estatutos y reglamentos, contratos, etc. 
                     </ListItem>
                     <ListItem>
                     d.	Reemplazar al vicepresidente, asumiendo sus funciones en caso de ausencia o renuncia mientras se elige su reemplazante.
                     </ListItem>
                     <ListItem>
                     e.	Convocar conjuntamente con el presidente a asambleas y reuniones del directorio.
                     </ListItem>
                 </List>
                </DialogContentText>
                <DialogContentText aling="justify">
                4.	SON FUNCIONES DEL SECRETARIO DE HACIENDA:
                 <List>
                     <ListItem>
                     a.	Controlar los fondos y activos de la Asociación que se produzcan por cualquier concepto
                     </ListItem>
                     <ListItem>
                     b.	Efectuar los pagos de las obligaciones contraídas, con la aprobación del presidente.
                     </ListItem>
                     <ListItem>
                     c.	Tener al día el movimiento contable de ingresos y egresos de los fondos de la Asociación.
                     </ListItem>
                     <ListItem>
                     d.	Llevar la lista de usuarios, con los cobros por la prestación del servicio y otros cobros que genere la Asociación.
                     </ListItem>
                     <ListItem>
                     e.	Llevar un registro de la recepción de materiales y herramientas con los que cuenta la Asociación.
                     </ListItem>
                     <ListItem>
                     f.	Entregar materiales y herramientas solicitados por los funcionarios, tomando nota en un registro 
                     </ListItem>
                     <ListItem>
                     g.	Controlar estrictamente la entrada y salida de materiales y herramientas
                     </ListItem>
                     <ListItem>
                     h.	Responsabilizarse por todos los materiales y herramientas que estén bajo su custodia.
                     </ListItem>
                     <ListItem>
                     i.	Comunicar a la Asociación la necesidad de adquirir materiales que se necesite para la prestación del servicio. La adquisición de bienes o servicios serán autorizados por el presidente previa presentación de cuadros comparativos con sus comprobantes establecidos y aprobados por la asamblea.
                     </ListItem>
                     <ListItem>
                     j.	Presentar cuadros comparativos para la adquisición de bienes o servicios de acuerdo a montos y comprobantes establecidos y aprobados por la asamblea.
                     </ListItem>
                 </List>
                </DialogContentText>
                <DialogContentText aling="justify">
                5.	SON FUNCIONES DEL SECRETARIO DE DEPORTES:
                <List>
                     <ListItem>
                     a.	Desarrollar planes, programas deportivos y recreativos, comprometidos con la justa entrega de un deporte diversificado y una recreación accesible, promoviendo la unión de las familias de la comunidad y su entorno.
                     </ListItem>
                     <ListItem>
                     b.	Difundir y motivar la participación activa y constante de los Asociados en las actividades deportivas.
                     </ListItem>
                     <ListItem>
                     c.	Coordinar y realizar actividades de deporte con la Asociación y/o con otras instituciones, buscando el desarrollo social sin olvidar los principios de respeto, responsabilidad, lealtad, honradez, trabajo en equipo y solidaridad.
                     </ListItem>
                     <ListItem>
                     d.	Garantizar que los procesos deportivos y recreativos se lleven a cabo de conformidad con los planes y programas establecidos.
                     </ListItem>
                     <ListItem>
                     e.	El Fondo para el deporte se conformará con los siguientes recursos:
                     <List>
                       <ListItem>
                       	Con programas o eventos especiales que se realicen para obtener ingresos para el Fondo.
                       </ListItem>
                       <ListItem>
                       	Con los obtenidos en su propio desarrollo.
                       </ListItem>
                       <ListItem>
                       	Con el aporte aprobado por la Asamblea.
                       </ListItem>
                       <ListItem>
                       	Con las donaciones y auxilios que se realicen por personas naturales o jurídicas con destino al incremento del Fondo.
                       </ListItem>
                     </List>
                     </ListItem>
                 </List>
                </DialogContentText>
                <DialogContentText>
                6.	SON FUNCIONES DE LOS VOCALES 1 Y 2.
                <List>
                  <ListItem>
                  a.	Citar a reuniones ordinarias y extraordinarias de la Asociación.
                  </ListItem>
                  <ListItem>
                  b.	Apoyar en las campañas de comunicación de la Asociación.
                  </ListItem>
                  <ListItem>
                  c.	Socializar los estatutos y reglamentos de la Asociación con los usuarios
                  </ListItem>
                  <ListItem>
                  d.	Comunicar a los usuarios en caso de cortes de servicio por mantenimiento, nuevos proyectos etc.
                  </ListItem>
                </List>
                </DialogContentText>
                <DialogContentText>
                DOCUMENTOS QUE MANEJA LA DIRECTIVA, SON LOS SIGUIENTES:
                <List>
                  <ListItem>
                  •	Un Libro de Actas. 
                  </ListItem>
                  <ListItem>
                  •	Plano del sistema (bombeo, aducción, almacenamiento, red).
                  </ListItem>
                  <ListItem>
                  •	Archivo de solicitudes y conexiones domiciliarias.
                  </ListItem>
                  <ListItem>
                  •	Registro de usuarios. 
                  </ListItem>
                  <ListItem>
                  •	Estados de cuentas de los usuarios.
                  </ListItem>
                  <ListItem>
                  •	Libro de ingresos y egresos (libro de Caja o Contabilidad). 
                  </ListItem>
                  <ListItem>
                  •	Talonarios de recibos numerados o tarjetas de control. Para el cobro de tarifas. 
                  </ListItem>
                  <ListItem>
                  •	Planillas del movimiento de caja mensual. 
                  </ListItem>
                  <ListItem>
                  •	Cartas y/o solicitudes de asistencia técnica. 
                  </ListItem>
                  <ListItem>
                  •	Registro de trabajos de reparación/mantenimiento y lectura.
                  </ListItem>
                </List>
                </DialogContentText>
                <DialogContentText align="center">
                  DEPARTAMENTO ADMINISTRATIVO
                </DialogContentText>
                <DialogContentText>
                <Grid container spacing={1}>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      CARGO
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                     NOMBRE
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      CELULAR
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      SECRETARIA / AUXILIAR CONTABLE
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                     SILVIA ROMERO YUCRA
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      77940253
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      PLOMERO
                  </Grid>
                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                      PASCUAL CHACON VISCARRA
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      65762430
                  </Grid>
                </Grid>
                </DialogContentText>
                
                <DialogContentText align="center">
                FUNCIONES DEL PERSONAL ADMINISTRATIVO
                </DialogContentText>
                <DialogContentText>
                NOMBRE DEL CARGO: AUXILIAR CONTABLE - SECRETARIA GENERAL
                <List>
                1.	PERFIL DEL CARGO
                     <ListItem>
                     A.	Educacion: Bachiller comercial, secretariado o auxiliar contable.
                     </ListItem>
                     <ListItem>
                     B.	Formación: Sistemas ofimáticos (Word, Excel, Power point), Cursos de archivo.
                     </ListItem>
                     <ListItem>
                     C.	Experiencia: Experiencia mínima de Un (1) año de experiencia.
                     </ListItem>
                 </List>
                </DialogContentText>
                <DialogContentText>
                <List>
                2.	HABILIDAD
                     <ListItem>
                      A.	Habilidad mental:  Planea su trabajo, con anticipación y de acuerdo a las prioridades existentes.
                     </ListItem>
                     <ListItem>
                      B.	Habilidad manual: realiza movimientos sencillos y normales.
                     </ListItem>
                 </List>
                 <List>
                 3.	RESPONSABILIDAD
                     <ListItem>
                     A.	Supervisión: Mantiene seguimiento a la información documental que reposa en el área de gerencia.
                     </ListItem>
                     <ListItem>
                     B.	Materiales:  Responde por lo elementos y equipos existentes en su puesto de trabajo.
                     </ListItem>
                     <ListItem>
                     C.	Manejo de valores: Maneja valores importantes para la empresa, como caja menor, chequeras, software y equipos.
                     </ListItem>
                 </List>
                 <List>
                 4.	MANUAL DE FUNCIONES Y COMPETENCIAS
                     <ListItem>
                     A.	Información: Maneja la información confidencial pertinente al desarrollo de las actividades de la empresa. 
                     </ListItem>
                     <ListItem>
                     B.	Otros: Es responsable de la entrega de informes a la gerencia.
                     </ListItem>
                 </List>
                 <List>
                 5.	ESFUERZO
                     <ListItem>
                     A.	Mental: Requiere mediana concentración mental para la ejecución de las tareas. 
                     </ListItem>
                     <ListItem>
                     B.	Verbal: Alto grado de concentración y manejo de comunicación con el cliente. 
                     </ListItem>
                     <ListItem>
                     C.	Visual: Alto grado de concentración visual para realizar las actividades satisfactoriamente. 
                     </ListItem>
                     <ListItem>
                     D.	Físico: El trabajo requiere un esfuerzo físico normal.
                     </ListItem>
                 </List>
                 <List>
                 6.	PROPÓSITO PRINCIPAL
                     <ListItem>
                     Apoyar las actividades de la gerencia ejecutando los procedimientos que dependan directamente de esta y mantener actualizada la información de su área para entregar al gerente.
                     </ListItem>
                 </List>
                 <List>
                 7.	DESCRIPCIÓN DE LAS FUNCIONES ESENCIALES
                     <ListItem>
                     •	Elaborar, responder y despachar correspondencia. 
                     </ListItem>
                     <ListItem>
                     •	Encargarse del cobro del consumo y otros.
                     </ListItem>
                     <ListItem>
                     •	Recibir mensajes vía telefónica y/o personal.
                     </ListItem>
                     <ListItem>
                     •	Recibir y radicar correspondencia en forma escrita o vía fax.
                     
                     </ListItem>
                     <ListItem>
                      •	Controlar las llamadas a larga distancia y locales.
                     </ListItem>
                     <ListItem>
                      •	Responder por el manejo de la caja menor, efectuar respectivo desembolso.
                     </ListItem>
                     <ListItem>                    
                      •	Diligenciar contratos de trabajo de servicio.
                     </ListItem>
                     <ListItem>                     
                      •	Llevar control sobre el libro de actas de reuniones que realicen la junta directiva y la asamblea general de socios.
                     </ListItem>
                     <ListItem>
                      •	Manejar el archivo de administración. 
                     </ListItem>
                     <ListItem>
                      •	Elaborar y hacer efectivas las citaciones y convocatorias a la junta directiva, comités y demás reuniones que se celebren en la asociación de agua potable y saneamiento básico de agua potable Mallco Chapi, al igual que transcribir las actas tanto de la junta directiva como de las demás reuniones a las cuales asista.
                     
                     </ListItem>
                     <ListItem>
                      •	Atender usuarios.                     
                     </ListItem>
                     <ListItem>
                     •	Realizar cotizaciones de materiales.
                     </ListItem>
                     <ListItem>                    
•	Elaborar órdenes de compra cuando el jefe de lectores y/o almacenista no se encuentre en la oficina.
                     </ListItem>
                     <ListItem>                    
•	Realizar impresión de auxiliares y libros contables.
                     </ListItem>
                     <ListItem>
•	Digitar contabilidad.
                     </ListItem>
                     <ListItem>
•	Manejar el archivo del área contable.
                     </ListItem>
                     <ListItem>
•	Generar lista de precios de materiales y accesorios para entregar al agente de atención al cliente.
                     </ListItem>
                     <ListItem>
•	Elaborar liquidación de nómina.
                     </ListItem>
                     <ListItem>
•	Generar la relación de materiales correspondientes a las liquidaciones mensuales, anexando soportes(fotocopias).
                     </ListItem>
                     <ListItem>
•	Generar recibos de venta por concepto de materiales obsoletos.
                     </ListItem>
                 </List>
                 <List>
                 8.	MANUAL DE FUNCIONES Y COMPETENCIAS
                     <ListItem>
                     •	Realizar cobros en caja, del consumo de agua potable y otros.
                     </ListItem>
                     <ListItem>
                     •	Verificar inventario físico.
                     </ListItem>
                     <ListItem>
                     •	Diligenciar información solicitada por los entes de control.
                     </ListItem>
                     <ListItem>
                     •	Liquidar y diligenciar formularios de pago (juntamente con el tesorero/a).
                     </ListItem>
                     <ListItem>
                     •	Elaborar conciliaciones bancarias (en coordinación con el presidente/a y tesorera/o)
                     </ListItem>
                     <ListItem>
                     •	Generar informe de materiales utilizados en las inversiones realizadas.
                     </ListItem>
                     <ListItem>
                     •	Manejar correspondencia de contabilidad.
                     </ListItem>
                     <ListItem>
                     •	Uso permanente de la dotación asignada.
                     </ListItem>
                     <ListItem>
                     •	Mantener en buen estado las herramientas de trabajo a su cargo.
                     </ListItem>
                     <ListItem>
                     •	Las demás funciones y tareas que le sean asignadas por su superior inmediato, que tengan relación con la naturaleza del empleo y de la dependencia.
                     </ListItem>
                     <ListItem>
                     •	Responder por papelería del área a su cargo.
                     </ListItem>
                     <ListItem>
                     •	Suministrar información de sus funciones.
                     </ListItem>
                     <ListItem>
                     •	Mantener en buen estado las herramientas de trabajo a su cargo.
                     </ListItem>
                     <ListItem>
                     •	Las demás funciones y tareas que le sean asignadas por su superior inmediato, que tengan relación con la naturaleza del empleo y de la dependencia.
                     </ListItem>
                 </List>
                 <List>
                 9.	RESPONSABILIDADES EN EL SISTEMA DE GESTIÓN INTEGRAL
                     <ListItem>
                     •	Cumplir la política de gestión integral.
                     </ListItem>
                     <ListItem>
                     •	Cumplir con las funciones establecidas en el manual defunciones, reglamentos y las responsabilidades definidas en los procedimientos del sistema de gestión.
                     </ListItem>
                     <ListItem>
                     •	Informar al área de Seguridad, Salud en el Trabajo todo accidente o incidente de trabajo.
                     </ListItem>
                     <ListItem>
                     •	Procurar el cuidado integral de su salud y Suministrar información clara, veraz y completa sobre su estado de salud.
                     </ListItem>
                 </List>
                 <List>
                 10.	CARACTERÍSTICAS DEL CARGO

                     <ListItemText>
                     El cargo se ubica en un sitio abierto, agradable y no mantiene contacto frecuente con agentes contaminantes. La persona mantiene posturas en posición sentado, la mayor parte del tiempo.
                     </ListItemText>
                 </List>
                </DialogContentText>
                <DialogContentText>
                NOMBRE DEL CARGO: PLOMERO
                <List>
                1.	PERFIL DEL CARGO
                     <ListItem>
                     A.	Educación: Bachiller comercial, académico o técnico. Curso básico de construcción, curso de plomería, mantenimiento de redes o afines. 
                     </ListItem>
                     <ListItem>
                     B.	Formación: Competencias laborales según lo definido en la normatividad técnica del sector agua potable y saneamiento básico Curso de alturas avanzado. Curso de espacios confinados. 
                     </ListItem>
                     <ListItem>
                     C.	Experiencia: Experiencia mínima de un (1) año en cargos relacionados.
                     </ListItem>
                 </List>
                </DialogContentText>
                <DialogContentText>
                <List>
                2.	EQUIVALENCIAS
                     <ListItem>
                     A.	Educación:	
                     </ListItem>
                     <ListItem>
                     B.	Experiencia: El título de educación por, mínimo dos (2) años de experiencia en cargos relacionados.
                     </ListItem>
                 </List>
                 <List>
                 3.	HABILIDADES
                     <ListItem>
                     A.	Habilidad mental:  Planea su trabajo, con anticipación y de acuerdo a las prioridades existentes.
                     </ListItem>
                     <ListItem>
                     B.	Habilidad manual: realiza movimientos difíciles (de esfuerzo), y poco normales.
                     </ListItem>
                 </List>
                 <List>
                 4.	RESPONSABILIDAD
                     <ListItem>
                     A.	Supervisión: Del buen desempeño de su trabajo. 
                     </ListItem>
                     <ListItem>
                     B.	Materiales: Responde por los equipos, materiales, insumos y otros elementos entregados para la ejecución de sus labores.
                     </ListItem>
                     <ListItem>
                     C.	Información: Maneja información confidencial, pertinente al desarrollo de las actividades de la empresa.
                     </ListItem>
                 </List>
                 <List>
                 5.	FUNCIONES Y COMPETENCIAS
                     <ListItem>
                     A.	Mental: Requiere una concentración normal para la ejecución de las tareas.
                     </ListItem>
                     <ListItem>
                     B.	Verbal: Alto grado de concentración y manejo de comunicación con usuarios.
                     </ListItem>
                     <ListItem>
                     C.	Visual: Alto grado de concentración visual para realizar las actividades satisfactoriamente.
                     </ListItem>
                     <ListItem>
                     D.	Físico: El trabajo requiere un esfuerzo físico (alto).
                     </ListItem>
                 </List>
                 <List>
                 6.	PROPÓSITO PRINCIPAL
                     <ListItem>
                     •	Reparar las redes de acueducto y alcantarillado.
                     </ListItem>
                 </List>
                 <List>
                 7.	DESCRIPCIÓN DE LAS FUNCIONES ESENCIALES
                     <ListItem>
                     •	Recibir orden de la Auxiliar y directiva en la asignación de las funciones diarias.
                     </ListItem>
                     <ListItem>
                     •	Ser responsable de las acciones de operación y mantenimiento del sistema de agua y/o saneamiento.
                     </ListItem>
                     <ListItem>
                     •	Realizar las lecturas de medidores correspondientes cada mes.
                     </ListItem>
                     <ListItem>
                     •	Solicitar materiales de herramientas necesarias según el tipo de daño.
                     </ListItem>
                     <ListItem>
                     •	Cambiar y reparar tuberías o accesorios que forman parte de la red de aducción, conducción y distribución.
                     </ListItem>
                     <ListItem>
                     •	Realizar mantenimiento a las redes acueducto.
                     </ListItem>
                     <ListItem>                    
                     •	Ejecutar las suspensiones, verificaciones y cortes del servicio, cuando se ordénene bajo notificaciones.
                     </ListItem>
                     <ListItem>                     
                     •	Realizar las reparaciones en el sistema de agua potable y/o saneamiento. 
                     </ListItem>
                     <ListItem>
                     •	Vigilar la fuente e instalaciones para preservar la calidad del agua y buena conservación del sistema.
                     </ListItem>
                     <ListItem>
                     •	Realizar nuevas conexiones y reconexiones previa autorización escrita de la Asociación, previo pago por parte del usuario o solicitante.
                     </ListItem>
                     <ListItem>
                     •	Elaborar un programa de actividades y dar a conocer a la Asociación las mismas, realizar las actividades de limpieza, desinfección del tanque e inspecciones periódicas que se realice. 
                     </ListItem>
                     <ListItem>
                     •	Elaborar informes técnicos periódicos al directorio de la Asociación.
                     </ListItem>
                     <ListItem>                    
                     •	Instalar, mantener y limpiar las cajas donde se encuentran ubicadas las válvulas.
                     </ListItem>
                     <ListItem>                    
                     •	Mantener limpias los predios de los pozos y tanques de agua potable.
                     </ListItem>
                     <ListItem>
                     •	Realizar el respectivo reparcheo de los daños realizados en la jornada de trabajo.
                     </ListItem>
                     <ListItem>
                     •	Colocar señalización de reparación en el área afectada.
                     </ListItem>
                     <ListItem>
                     •	Entregar las respectivas novedades a la auxiliar o secretaria, del material y herramientas necesarias según el tipo de daño o limpieza.
                     </ListItem>
                     <ListItem>
                     •	Recoger escombros y desechos generados por la jornada de trabajo.
                     </ListItem>
                     <ListItem>
                     •	Suministrar información de sus funciones.
                     </ListItem>
                     <ListItem>
                     •	El uso permanente de la dotación asignada.
                     </ListItem>
                     <ListItem>
                     •	Mantener en buen estado las herramientas de trabajo a su cargo.
                     </ListItem>
                     <ListItem>
                     •	Las demás funciones y tareas que le sean asignadas por su superior inmediato, que tengan relación con la naturaleza del empleo y de la dependencia. 
                     </ListItem>
                     <ListItem>
                     •	Ejecutar las sanciones impuestas por la Asociación (autorizadas de forma escrita) como cortes de servicio. 
                     </ListItem>
                 </List>
                 <List>
                 8.	MANUAL DE FUNCIONES Y COMPETENCIAS
                     <ListItem>
                     •	Cumplir la política de gestión integral.
                     </ListItem>
                     <ListItem>
                     •	Cumplir   con   las   funciones   establecidas   en   el   manual   defunciones   y   las responsabilidades definidas en los procedimientos del sistema de gestión. 
                     </ListItem>
                     <ListItem>
                     •	Cumplir los reglamentos y políticas de la organización.
                     </ListItem>
                     <ListItem>
                     •	Informar al área de Seguridad, Salud en el Trabajo las restricciones médicas derivadas por enfermedades comunes o laborales. 
                     </ListItem>
                     <ListItem>
                     •	Informar al área de Seguridad, Salud en el Trabajo todo accidente o incidente de trabajo. 
                     </ListItem>
                     <ListItem>
                     •	Asistir a las capacitaciones programadas dentro del Sistema de Gestión Integral
                     </ListItem>
                     <ListItem>
                     •	Procurar el cuidado integral de su salud y Suministrar información clara, veraz y completa sobre su estado de salud.
                     </ListItem>
                     <ListItem>
                     •	Informar oportunamente al empleador o contratante acerca de los peligros y riesgos latentes en su sitio de trabajo.
                     </ListItem>
                 </List>
                 <List>
                 9.	CARACTERÍSTICAS DEL CARGO
                     <ListItemText>
                     El cargo se ubica en campo abierto, mantiene frecuentemente contacto con agentes contaminantes y sustancias químicas. La persona mantiene posturas en posición de pie, la mayor parte del tiempo, realiza actividades de esfuerzos físicos.
                     </ListItemText>
                 </List>
                </DialogContentText>
              </DialogContent>
            </Dialog>
              <Button onClick={handleClickNosotros('paper')}>NOSOTROS</Button>
{/* Plan de Trabajo */}

            <Dialog
              open={planTrabajo}
              onClose={handleClosePlanTrabajo}
              scroll={scrollPlanTrabajo}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">Plan de Trabajo</DialogTitle>
              <DialogContent dividers={scrollPlanTrabajo === 'paper'}>
                <DialogContentText
                  id="scroll-dialog-description"
                  tabIndex={-1}
                  align="justify"
                >
            <paragraph>
              <Typography>
                META
              </Typography>
                <paragraph>
                La asociación de agua potable y saneamiento de MALLCO CHAPI presta un mejor servicio administrativo y operativo para un mejor vivir de los usuarios de la comunidad.
                </paragraph>
                <paragraph>
                OBJETIVOS
                </paragraph>
                <paragraph>
                General
                </paragraph>
                <paragraph>
                Contribuir al desarrollo de la comunidad de MALLCO CHAPI, mejorando la calidad de vida de sus habitantes mediante la prestación eficiente, transparente y sostenible de servicios de agua potable con calidad, continuidad, cobertura y costos adecuados.
                </paragraph>
            </paragraph>

            
            <paragraph>
              <List>
                <paragraph>
                Especifico
                </paragraph>
              <ListItem>
                1. Lograr que funcione un mejor sistema de control con balance económico.
              </ListItem>
              <ListItem>
                2. Continuación de los tramites AAPS y personería jurídica.
                
              </ListItem>
              <ListItem>
                3. Contar con un terreno para un centro de purificación de agua y almacenamiento de agua.
              </ListItem>
              <ListItem>
                4. Nueva perforación de pozo.
              </ListItem>
              <ListItem>
                5. Hacer estudios sobre conexiones de red para un mejor servicio.
              </ListItem>
              <ListItem>
                6. Regularizar la parte administrativa:
              </ListItem>
              <ListItem>
                <List>
                  <ListItem>
                    • Ingresos egresos regulares.
                  </ListItem>
                  <ListItem>
                  • Registro y evaluación de los usuarios.
                  </ListItem>
                  <ListItem>
                  • Operativo de plomería.
                  </ListItem>
                </List>
              </ListItem>
              </List>
            </paragraph>

            
            <paragraph>
              <List>
                <ListItem>Área Administrativa</ListItem>
                <ListItem>A. Compra de un nuevo de sistema de cobros.</ListItem>
                <ListItem>B.  Copra de sonido, para las reuniones.</ListItem>
                <ListItem>C.  Cumplir con los beneficios de los trabajadores</ListItem>
                <ListItem>D.  Regularización de todo el manejo administrativo.</ListItem>
              </List>
            </paragraph>
            <paragraph>
              <List>
                <ListItem>Área operativa</ListItem>
                <ListItem>A.  Ampliaciones o cambio de matrices previa solicitud.</ListItem>
                <ListItem>B.  Refacciones de las matrices con fuga.</ListItem>
                <ListItem>C.  Refacciones de piletas comunales.</ListItem>
              </List>
            </paragraph>
            <paragraph>
              <List>
                <ListItem>Área de proyectos</ListItem>
                <ListItem>A.  Hacer la compra de un terreno para un centro de purificación de agua y almacenamiento de agua, en lo posible en la cabecera de la comunidad.</ListItem>
                <ListItem>B.  Nueva perforación de pozo.</ListItem>
                <ListItem>C.  Estudio de tanque de agua para el nuevo pozo.</ListItem>
                <ListItem>D.  Elaboración de proyecto de cambio de red (matrices) de agua potable.</ListItem>
              </List>
            </paragraph>
            <paragraph>
              <List>
                <ListItem>Área legal</ListItem>
                <ListItem>A.  Trámite de APS</ListItem>
                <ListItem>B.  Trámite de INRA</ListItem>
                <ListItem>C.  Seguimiento del proceso (propiedad compartida)</ListItem>
                <ListItem>D.  Revisión y modificación del estatuto y reglamentos. (formas de ingresos)</ListItem>
              </List>
            </paragraph>

            <paragraph>
              <List>
                <ListItem>Área de mantenimiento.</ListItem>
                <ListItem>A.  Mantenimiento de los dos pozos de agua potable. </ListItem>
                <ListItem>B.  Mantenimiento de las piletas comunales. </ListItem>
              </List>
            </paragraph>

            <paragraph>
              <List>
                <ListItem>Apertura de nuevos ingresantes.</ListItem>
                <ListItem>A.  Realizar una inspección de viviendas quienes no cuentan con agua.</ListItem>
                <ListItem>B.  Un control exhaustivo para nuevos ingresantes.</ListItem>
              </List>
            </paragraph>
                </DialogContentText>
              </DialogContent>
            </Dialog>
              <Button onClick={handleClickPlanTrabajo('paper')}>Plan de Trabajo</Button>
{/* Deudores */}
            {/* <Dialog
              open={deudores}
              onClose={handleCloseDeudores}
              scroll={scrollDeudores}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogTitle id="scroll-dialog-title">CONTACTOS</DialogTitle>
              <DialogContent dividers={scrollDeudores === 'paper'}>
                <DialogContentText
                  id="scroll-dialog-description"
                  tabIndex={-1}
                  align="justify"
                >
                  <Typography variant="h4">
                  En Construccion...
                  </Typography>
                </DialogContentText>
              </DialogContent>
            </Dialog> */}

            <Button target="_blank" href='https://mail.aguapotable.org/'>CORREO</Button>

            </ButtonGroup>
          </Grid>
        </Grid>
      </Box>
    </div>
  
    );
};
export default Notice;