import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { useStateValue } from "../../contexto/store";
import { deleteEvent, getListEvents, saveEvent, updateEvent } from '../../actions/AssociateAction';

const EventRegister = () => {
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteEventId, setDeleteEventId] = useState(null);
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    dateEvent: '',
    timeLate: '',
    timeMaxLate: '',
    onlyEntry: false 
  });

  const [pagedResponse, setPagedResponse] = useState({
    listRecords: [],
    totalRecords: 0,
    numberPageds: 0,
  });
  const [editingIndex, setEditingIndex] = useState(null);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  useEffect(() => {
    const listEvents = async () => {
      const objectPagedRequest = {
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      };

      const response = await getListEvents(objectPagedRequest);
      setPagedResponse(response.data);
    };
    listEvents();
  }, [page, rowsPerPage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingIndex !== null) {
      const updatedEvents = [...pagedResponse.listRecords];
  
      updateEvent(pagedResponse.listRecords[editingIndex].eventId, formValues).then((response) => {
        let message = "";
        formValues.dateEvent = formatDateStringTable(formValues.dateEvent);
        updatedEvents[editingIndex] = formValues;
  
        if (response.status === 200) {
          message += "Se guardaron los cambios exitosamente";
          setPagedResponse({
            ...pagedResponse,
            listRecords: updatedEvents
          });
        } else {
          message += "Errores :" + Object.keys(response.data.errors);
        }
  
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: message,
          },
        });
  
      });
      setEditingIndex(null);
    } else {
      saveData();
    }
    setFormValues({
      name: '',
      description: '',
      dateEvent: '',
      timeLate: '',
      timeMaxLate: '',
      onlyEntry: false
    });
  };
  

  const saveData = () => {
    saveEvent(formValues).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardó exitosamente el nuevo evento";
        getListEvents({ pageNumber: page + 1, pageSize: rowsPerPage }).then(response => {
          setPagedResponse(response.data);
        });
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });
    });
  };

  const formatDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    return `${year}-${month}-${day}T${timePart}`;
  };

  const formatDateStringTable = (dateString) => {
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    return `${day}/${month}/${year} ${timePart}`;
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    const selectedEvent = pagedResponse.listRecords[index];
    let formattedDate = selectedEvent.dateEvent;

    if (formattedDate) {
      formattedDate = formatDateString(formattedDate);
    } else {
      formattedDate = '';
    }

    setFormValues({
      ...selectedEvent,
      dateEvent: formattedDate,
      onlyEntry: selectedEvent.onlyEntry 
    });
  };

  const handleDelete = (index, eventId) => {
    const updatedEvents = pagedResponse.listRecords.filter((_, i) => i !== index);
    setPagedResponse({
      ...pagedResponse,
      listRecords: updatedEvents
    });

    deleteEvent(eventId).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se eliminó exitosamente el evento";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      setFormValues({
        name: '',
        description: '',
        dateEvent: '',
        timeLate: '',
        timeMaxLate: '',
        onlyEntry: false
      });
    });
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  const handleClickOpen = (index, eventId) => {
    setDeleteIndex(index);
    setDeleteEventId(eventId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    handleDelete(deleteIndex, deleteEventId);
    handleClose();
  };

  return (
    <Container>
      <form onSubmit={handleSubmit} style={{ marginBottom: '20px' }}>
        <FormControlLabel
          control={
            <Checkbox
              name="onlyEntry"
              checked={formValues.onlyEntry}
              onChange={handleInputChange}
              color="primary"
            />
          }
          label="Solo ingreso?"
        />
        <TextField
          label="Nombre"
          name="name"
          value={formValues.name}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Descripción"
          name="description"
          value={formValues.description}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Fecha y Hora de Evento"
          name="dateEvent"
          type="datetime-local"
          value={formValues.dateEvent}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
        <TextField
          label="Tiempo de tolerancia (en minutos)"
          name="timeLate"
          type="number"
          value={formValues.timeLate}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Tiempo maximo de Retraso(en minutos)"
          name="timeMaxLate"
          type="number"
          value={formValues.timeMaxLate}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">
          {editingIndex !== null ? 'Actualizar Evento' : 'Crear Evento'}
        </Button>
      </form>

      {/* Tabla de eventos */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>Solo ingreso</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Fecha de Evento</TableCell>
              <TableCell>Tiempo de tolerancia</TableCell>
              <TableCell>Tiempo máximo de retraso</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedResponse.listRecords.map((event, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox checked={event.onlyEntry} disabled />
                </TableCell> 
                <TableCell>{event.name}</TableCell>
                <TableCell>{event.description}</TableCell>
                <TableCell>{event.dateEvent}</TableCell>
                <TableCell>{event.timeLate}</TableCell>
                <TableCell>{event.timeMaxLate}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(index)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleClickOpen(index, event.eventId)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={pagedResponse.totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página"
          style={{ backgroundColor: '#f5f5f5', color: '#333' }}
        />
      </TableContainer>

      {/* Diálogo de confirmación */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que deseas eliminar este evento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EventRegister;
