import { createMuiTheme  } from '@material-ui/core/styles';



// #2A6049    #2A6049
// #53856a    #5E8F72
// #BDD0CA    #C2D1CC
// #CFDED7    #0059c1
// #EAEEEC    #4285F4
//            #80b4ff



const theme = createMuiTheme({
    palette : {
        primary : {

            light: '#CFDED7',
            main: '#2A6049',
            dark: '#53856a',
            contrastText: '#fff',
        },
        secondary: {
            light : "#80b4ff",
            main : "#4285F4",
            dark : "#0059c1",
            contrastText : "#fff"
        },
        error: {
            light : "#e57373",
            main : "#f44336",
            dark : "#d32f2f",
            contrastText : "#fff"
        },
        background: {
            paper : "#BDD0CA",
            default : "#CFDED7",
            dark : "#000",
            contrastText : "#000"
        },
        
        text: {
            primary : "#000",
            secondary : "#000",
            dark : "#fff",
            contrastText : "#fff"
        },
        textWhite: {
          primary : "#fff",
          secondary : "#fff",
          dark : "#fff",
          contrastText : "#fff"
      },
        
    },
    overrides: {
        MuiSelect: {
          select: {
            "&:focus": {
              backgroundColor: '#fff',
              color: '#000',
              
            },
            '&:before': {
              borderColor: 'linear-gradient(45deg, #53856a, #CFDED7, #2A6049)'
            },
            '&:after': {
              borderColor: '#000',
            },
            minHeight:250,
            width:400
          }
        },
    },
    shape: {
      borderRadius:20,
    },
});

export default theme;