import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Button, Box, Checkbox, FormControlLabel, Chip, Typography, TextField } from '@material-ui/core';
import { createAttendance, getAllEvents, getAllMeasurer } from '../../actions/AssociateAction';
import Alert from '@material-ui/lab/Alert';
import { useStateValue } from '../../contexto/store';

const AttendanceRegister = () => {
  const [events, setEvents] = useState([]);
  const [members, setMembers] = useState([]);
  const [details, setDetails] = useState({ selectedEvent: null, selectedMembers: [], addAllMembers: false });
  const [error, setError] = useState('');
  const [range, setRange] = useState({ start: '', end: '' });
  const [{ sesionUsuario }, dispatch] = useStateValue();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getAllEvents();
        setEvents(response.data);
      } catch (error) {
        console.error('Error al obtener eventos:', error);
      }
    };

    const fetchMembers = async () => {
      try {
        const response = await getAllMeasurer();
        setMembers(response.data);
      } catch (error) {
        console.error('Error al obtener socios:', error);
      }
    };


    fetchEvents();
    fetchMembers();
  }, []);

  const handleEventClick = (event) => {
    setDetails({ ...details, selectedEvent: event });
    setError('');
  };

  const handleMemberClick = (member) => {
    if (!details.selectedMembers.includes(member)) {
      setDetails({ ...details, selectedMembers: [...details.selectedMembers, member] });
    }
    setError('');
  };

  const handleMemberRemove = (member) => {
    setDetails({
      ...details,
      selectedMembers: details.selectedMembers.filter((m) => m !== member)
    });
  };

  const handleAddAllMembersChange = (event) => {
    setDetails({ ...details, addAllMembers: event.target.checked, selectedMembers: event.target.checked ? [] : details.selectedMembers });
    setError('');
  };

  const handleRegister = () => {
    if (!details.selectedEvent) {
      setError('Debe seleccionar un evento.');
      return;
    }
    if (!details.addAllMembers && details.selectedMembers.length === 0) {
      setError('Debe seleccionar al menos un socio.');
      return;
    }

    const payload = {
      event: details.selectedEvent,
      members: details.addAllMembers ? [] : details.selectedMembers,
      addAllMembers: details.addAllMembers
    };

    createAttendance(payload).then((response) => {
      let message = "";

      if (response.status === 200) {
        message += "Se guardó exitosamente";

      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      handleCleaner();
    });
  };

  const handleCleaner = () => {
    setDetails({ selectedEvent: null, selectedMembers: [], addAllMembers: false });
    setError('');
  };

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    setRange({ ...range, [name]: value });
  };

  const handleAddRangeMembers = () => {
    const start = parseInt(range.start, 10);
    const end = parseInt(range.end, 10);

    if (isNaN(start) || isNaN(end) || start > end) {
      setError('El rango debe ser válido y el inicio debe ser menor o igual al fin.');
      return;
    }

    const membersInRange = members.filter(member => {
      const memberCode = parseInt(member.code, 10);
      return memberCode >= start && memberCode <= end;
    });

    setDetails({ ...details, selectedMembers: [...new Set([...details.selectedMembers, ...membersInRange])] });
    setError('');
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Paper style={{ padding: 5, textAlign: 'center', margin: 'auto' }}>
            <Typography variant="h4">
              Eventos
            </Typography>
            <Box display="flex" flexWrap="wrap" p={1} m={1}>
              {events.map((event) => (
                <Chip
                  key={event.eventId}
                  label={event.name}
                  clickable
                  onClick={() => handleEventClick(event)}
                  disabled={details.selectedEvent && details.selectedEvent.eventId === event.eventId}
                  style={{ margin: 2 }}
                />
              ))}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper style={{ padding: 5, textAlign: 'center', margin: 'auto' }}>
            <Typography variant="h4">
              Detalles
            </Typography>
            {error && (
              <Alert severity="error" style={{ marginBottom: 10 }}>
                {error}
              </Alert>
            )}
            <Box display="flex" flexWrap="wrap" p={1} m={1}>
              {details.selectedEvent && (
                <>
                  <Chip
                    label={`${details.selectedEvent.name}`}
                    onDelete={() => setDetails({ ...details, selectedEvent: null })}
                    style={{ margin: 2 }}
                  />
                </>
              )}
            </Box>
            {details.addAllMembers ? (
              <Box display="flex" flexWrap="wrap" p={1} m={1}>
                <Chip label="Todos los socios" onDelete={() => setDetails({ ...details, addAllMembers: false })} style={{ margin: 2 }} />
              </Box>
            ) : (
              <>
                <Typography variant="h6">
                  Socios (clic para eliminar):
                </Typography>
                <Box display="flex" flexWrap="wrap" p={1} m={1}>
                  {details.selectedMembers.map((member) => (
                    <Chip key={member.code} label={member.code} onClick={() => handleMemberRemove(member)} style={{ margin: 2 }} />
                  ))}
                </Box>
              </>
            )}
            <Button variant="contained" color="primary" onClick={handleRegister}>
              Registrar
            </Button>
            <Button variant="contained" color="secondary" onClick={handleCleaner} style={{ marginLeft: 10 }}>
              Limpiar
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper style={{ padding: 5, textAlign: 'center', margin: 'auto' }}>
            <Typography variant="h4">
              Medidores
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={details.addAllMembers} onChange={handleAddAllMembersChange} />}
              label="Agregar todos"
            />
            {!details.addAllMembers && (
              <>
                <Box display="flex" flexDirection="column" alignItems="center" p={1} m={1}>
                  <TextField
                    label="Inicio"
                    name="start"
                    value={range.start}
                    onChange={handleRangeChange}
                    type="number"
                    style={{ marginBottom: 10 }}
                  />
                  <TextField
                    label="Fin"
                    name="end"
                    value={range.end}
                    onChange={handleRangeChange}
                    type="number"
                    style={{ marginBottom: 10 }}
                  />
                  <Button variant="contained" color="primary" onClick={handleAddRangeMembers}>
                    Agregar rango
                  </Button>
                </Box>
                <Paper style={{ maxHeight: 400, overflow: 'auto' }}>
                  <Box display="flex" flexWrap="wrap" p={1} m={1}>
                    {members.map((member) => (
                      <Chip
                        key={member.code}
                        label={member.code}
                        onClick={() => handleMemberClick(member)}
                        disabled={details.selectedMembers.includes(member)}
                        style={{ margin: 2 }}
                      />
                    ))}
                  </Box>
                </Paper>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AttendanceRegister;
