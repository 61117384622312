import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { Grid } from "@material-ui/core";
import person from "../../person.jpg";
import ListTableConsumption from "./ListTableConsumption";
import Styles from "../Tool/Style";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { useStateValue } from "../../contexto/store";
import {
  saveConsumptionAction,
  getListConsumptionPdf,
} from "../../actions/ConsumptionAction";

const MeasurerCard = (value) => {
  const [{ sesionUsuario }, dispatch] = useStateValue();
  const classes = Styles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateSelect, setDateSelect] = useState(new Date());
  const [startCant, setStartCant] = useState("");
  const [currentCant, setCurrentCant] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeDataInt = (value) => {
    if (null == value || "0" == value) {
      return 0;
    }
    return parseInt(value);
  };

  const calculateValue = () => {
    return calculateConsumption() * value.classification.price;
  };

  const calculateConsumption = () => {
    return changeDataInt(currentCant) - changeDataInt(startCant);
  };

  const registerNewData = () => {
    // e.preventDefault();
    const objectConsumption = {
      dateConsumption: dateSelect,
      previusConsumption: changeDataInt(startCant),
      start: changeDataInt(startCant),
      present: changeDataInt(currentCant),
      price: calculateValue(),
      totalConsumption: calculateConsumption(),
      isStart: true,
      detailStart: "Este es una iniciacion del consumo",
      measurerId: value.measurerId,
    };

    saveConsumptionAction(objectConsumption).then((response) => {
      let message = "";

      if (response.status === 200) {

        const listPdf = async () => {
          const res = await getListConsumptionPdf(response.data);
          const file = new Blob([res.data], {
            type: "application/pdf"
          });
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);

        };
        listPdf();

        message += "Se guardo exitosamente el inicio";
      } else {
        message += "Errores :" + Object.keys(response.data.errors);
      }

      dispatch({
        type: "OPEN_SNACKBAR",
        openMensaje: {
          open: true,
          mensaje: message,
        },
      });

      setOpen(false);
      setIsLoading(!isLoading);
    });
  };

  const addValueTemp = (e) => {
    setStartCant(e.target.value);
  };

  const addValueTempC = (e) => {
    setCurrentCant(e.target.value);
  };

  return (
    <Card className={classes.rootCar}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatarC} style={{ backgroundColor: "#000" }}>
            {value.code.substring(0, 3)}
          </Avatar>
        }
        titleTypographyProps={{ variant: 'h6' }}
        subheaderTypographyProps={{ variant: 'h6' }}
        title={"Codigo Medidor : " + value.code}
        subheader={value.associate.lastName + " " + value.associate.name}

      />

      <Grid container spacing={1}>
        <Grid container item xs={12} md={3} direction="column" alignItems="center">
          <Avatar
            variant="square"
            className={classes.avatarMeasure}
            src={
              `data:image/${value.associate.extensionPhoto};base64,${value.associate.photo}`
            }
          />
          <Typography className={classes.LePri} >{value.classification.description}</Typography>
          <Typography>Precio Cubo : {value.classification.price}</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickOpen}
            style={{ textTransform: "none" }}
            size="small"
          >
            Iniciar Medidor
          </Button>
        </Grid>

        <Grid item xs={12} md={9}>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <Typography variant="h4" align="center">
                INICIAR MEDIDOR
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Formulario para iniciar consumo del medidor:
                <b>{value.code} </b> <br />
                SOCIO : <b>{value.associate.name} {value.associate.lastName} </b>
              </DialogContentText>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale} >
                <KeyboardDatePicker
                  value={dateSelect}
                  onChange={setDateSelect}
                  margin="normal"
                  id="fecha-publicacion-id"
                  label="Seleccione mes de consumo"
                  format="MM/yyyy"
                  fullWidth
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  cancelLabel="Cancelar"
                  okLabel="Seleccionar"
                  views={["month", "year"]}

                />
              </MuiPickersUtilsProvider>

              <TextField
                name="startCant"
                value={startCant}
                onChange={addValueTemp}
                variant="outlined"
                fullWidth
                label="Ingrese consumo Inicial"
              />

              <TextField
                name="currentCant"
                value={currentCant}
                onChange={addValueTempC}
                variant="outlined"
                fullWidth
                label="Ingrese consumo actual"
                style={{ marginTop: 3 }}

              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancelar
              </Button>
              <Button onClick={registerNewData} color="primary" variant="contained">
                Guardar
              </Button>
            </DialogActions>
          </Dialog>

          <ListTableConsumption
            key={isLoading}
            valueM={value.measurerId}
            valueP={value.classification.price}
            name={value.associate.lastName + " " + value.associate.name}
            code={value.code}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default MeasurerCard;
