import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { getAllAttendancePdf, getListDoneEvents, getReportAssociate } from '../../actions/AssociateAction';

const LastAttendance = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [pagedResponse, setPagedResponse] = useState({
    listRecords: [],
    totalRecords: 0,
    numberPageds: 0,
  });
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [reportType, setReportType] = useState('');

  const fetchEvents = async (pageNumber, pageSize, searchQuery) => {
    const objectPagedRequest = {
      pageNumber: pageNumber + 1,
      pageSize: pageSize,
      searchQuery: searchQuery,
    };
    const response = await getListDoneEvents(objectPagedRequest);
    setPagedResponse(response.data);
  };

  useEffect(() => {
    fetchEvents(page, rowsPerPage, searchQuery);
  }, [page, rowsPerPage, searchQuery]);

  const handlePrint = (eventId) => {
    setSelectedEventId(eventId);
    setOpenReportDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0); // Reset to the first page on new search
    fetchEvents(0, rowsPerPage, searchQuery);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
    setReportType('');
  };

  const handleGenerateReport = async () => {
    const item = {
      eventId: selectedEventId,
      reportType: reportType,
    };
    const listExcel = async (dataItem) => {
      const res = await getReportAssociate(dataItem);
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };
    listExcel(item);
    handleCloseReportDialog();
  };

  

  const handleGeneratePdfReport = (eventId) => {
    const listPdf = async () => {
      const objectRequest = {
        eventId: eventId,
      };
      const res = await getAllAttendancePdf(objectRequest);
      const file = new Blob([res.data], {
        type: "application/pdf",
      });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    };
    listPdf();
  };
  return (
    <Container style={{ marginTop: '50px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <TextField
          label="Buscar por nombre"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Buscar
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre evento</TableCell>
              <TableCell>Fecha evento</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagedResponse.listRecords.map((event, index) => (
              <TableRow key={index}>
                <TableCell>{event.name}</TableCell>
                <TableCell>{event.dateEvent}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePrint(event.eventId)}
                    style={{ marginRight: '10px' }}
                  >
                    Reporte Excel
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => handleGeneratePdfReport(event.eventId)}
                    style={{ marginRight: '10px' }}
                  >
                    Reporte PDF
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={pagedResponse.totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página"
          style={{ backgroundColor: '#f5f5f5', color: '#333' }}
        />
      </TableContainer>

      {/* Dialogo para generar reporte */}
      <Dialog open={openReportDialog} onClose={handleCloseReportDialog}>
        <DialogTitle>Generar Reporte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione el tipo de reporte para el evento.
          </DialogContentText>
          <FormControl fullWidth>
            <InputLabel>Tipo de Reporte</InputLabel>
            <Select
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              <MenuItem value="Permiso">Permisos</MenuItem>
              <MenuItem value="Falta">Faltas</MenuItem>
              <MenuItem value="Presente">Presentes</MenuItem>
              <MenuItem value="Retraso">Retrasados</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReportDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleGenerateReport} color="primary">
            Generar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LastAttendance;
