import React, { useState } from "react";
// import FotoUsuarioTemp from "../../../logo.svg";
import { useStateValue } from "../../../contexto/store";
import CajonIzquierda from "./CajonIzquierda";
import { withRouter, Link } from "react-router-dom";
import clsx from "clsx";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircletIcon from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from "@material-ui/core/styles";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import "../../../App.css";

import {
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Drawer,
  Badge,
  AppBar,
  Divider,
} from "@material-ui/core";
import {LogoIcon} from "../../Tool";

const drawerWidth = 238;

const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
    },

    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
    },

    // toolbar: {
    //   paddingRight: 20 // keep right padding when drawer closed
    // },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
        
    menuButton: {
      marginRight: 5,
    },

    Hidden:{
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },

    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(0) + 0,
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(7) + 1,
      },
    },

    title: {
      flexGrow: 1
    },

    toolbar:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(7),
    },
    
    seccionDesktop :{
      display : "none",
      [theme.breakpoints.up("md")]:{
        display:"flex",
      },
    },
    seccionMobile :{
      display : "flex",
      [theme.breakpoints.down("sm")]:{
        display:"none",
      },
    },
    LogoIconC: {
      // fontSize:502,
      // marginBottom:50,
      marginBottom:-125,
      fontSize:'10rem',
      color:"primary",
  },
   
}));

  const BarSesion = (props) => {
  
  const classes = useStyles();
  
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [{ sesionUsuario }, dispatch] = useStateValue();

  const [open, setOpen] = useState(true);

// menu derecha verda
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const salirSesionApp = () => {
    localStorage.removeItem("token_seguridad");

    dispatch({
       type : "SALIR_SESION",
       nuevoUsuario : null,
       autenticado : false
    })

    props.history.push("/");
  };

  return (
    <div className={classes.root}>
      
      <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      
    >
        <Toolbar style={{marginLeft:-15}}>
          {/* menu hamurguesa */}
          <IconButton
              // edge="start"
              color="inherit"
              // aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              
              <MenuIcon/>
            </IconButton>

          {/* Titulo */}
          <Typography
              component="h2"
              variant="h6"
              // color="primary"
              noWrap
              className={classes.title}
            >
              Asociacion de Agua Potable Mallco Chapi
            </Typography>
        
        {/* boton salir */}
          <IconButton color="inherit">
              <Badge>
                <AccountCircletIcon
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem 
                    onClick={handleClose} 
                    component={Link} to="/">
                    Perfil de usuario
                  </MenuItem>
                  <MenuItem onClick={salirSesionApp} component={Link} to="/">
                    Cerrar sesion
                    {sesionUsuario ? sesionUsuario.usuario.nombreCompleto : ""}
                  </MenuItem>
                </Menu>
              </Badge>
            </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}

      >
        <div className={classes.toolbar}>
          <Typography
              component="h2"
              variant="h6"
              // color="primary"
              noWrap
              // className={classes.title} 
            >
              <LogoIcon color="primary" className={classes.LogoIconC}/>
          </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider/>

        <CajonIzquierda/>  

      </Drawer>
        
      
    </div>
    
  );
};

export default withRouter(BarSesion);
