import React from 'react';
import { Tooltip } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const MenuAttendance = [  
    {
        title:"Registrar Eventos",
        icon:
        <Tooltip title="Registrar Eventos">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/attendance/events"
    },  
    {
        title:"Crear Asistencia",
        icon:
        <Tooltip title="Crear Asistencia">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/attendance/register"
    },   
    {
        title:"Control Asistencia",
        icon:
        <Tooltip title="Control Asistencia">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/attendance/control"
    }, 
    {
        title:"Administrar Asistencia",
        icon:
        <Tooltip title="Administrar Asistencia">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/attendance/admin"
    },
    {
        title:"Asistencias Pasadas",
        icon:
        <Tooltip title="Asistencias Pasadas">
        <ReceiptIcon/>
        </Tooltip>,
        link: "/attendance/last"
    },    
];


