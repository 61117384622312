import React, { useState } from "react";
import Style from "../Tool/Style";
import { Typography, TextField, Button, Grid } from "@material-ui/core";
import HowToRegIcon from '@material-ui/icons/HowToReg';

import { loginUsuario } from "../../actions/UsuarioAction";
import { withRouter } from "react-router-dom";
import { useStateValue } from "../../contexto/store";

import { LogoIcon } from "../Tool";
import Notice from "../../componentes/Notice/Notice";

const Login = (props) => {
  const [{ usuarioSesion }, dispatch] = useStateValue();

  const [usuario, setUsuario] = useState({
    Email: "",
    Password: "",
  });

  const ingresarValoresMemoria = (e) => {
    const { name, value } = e.target;
    setUsuario((anterior) => ({
      ...anterior,
      [name]: value,
    }));
  };

  const loginUsuarioBoton = (e) => {
    e.preventDefault();
    loginUsuario(usuario, dispatch).then((response) => {
      if (response.status === 200) {
        window.localStorage.setItem("token_seguridad", response.data.token);
        props.history.push("/");
      } else {
        dispatch({
          type: "OPEN_SNACKBAR",
          openMensaje: {
            open: true,
            mensaje: "Las credenciales del usuario son incorrectas",
          },
        });
      }
    });
  };

  const clases = Style();

  return (
    <div
      component="main"
      justify="center"
      
    >
      <div className={clases.paperUno}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={9}
          >
          <Notice />
          </Grid>
          <Grid item xs={12} md={3} className={clases.fondoLogin} >
            <LogoIcon color="primary" className={clases.LogoIcon} />
            <Typography component="h1" variant="h4" style={{ color:'White' }}>
              INICIO DE SESION
            </Typography>
            <form className={clases.form}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    name="Email"
                    value={usuario.Email}
                    onChange={ingresarValoresMemoria}
                    variant="outlined"
                    label="Ingrese su usuario"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    name="Password"
                    value={usuario.Password}
                    onChange={ingresarValoresMemoria}
                    variant="outlined"
                    type="password"
                    label="Contraseña"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  onClick={loginUsuarioBoton}
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<HowToRegIcon style={{marginLeft:20, fontSize:36}}/>}
                  className={clases.submit}
                >
                  INGRESAR
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(Login);
